import bamboCategory from "./images/bamboo.jpg"
import stoolCategory from "./images/stools.jpg"
import sofaCategory from "./images/sofaset.jpg"
import newbornBlackCradle from "./images/newbornBlackCradle.jpg"
import newBornBasket from "./images/basket.jpg"
import newBornPinkCradel from "./images/newBornPinkCradel.jpg"
import babyChair from "./images/kidChair5.jpg"
import bambooSofa from "./images/bambooSofa.jpg"
import tray from "./images/tray.jpg"
import RexineChair from "./images/RexineChair.jpg"
import hangingLight from "./images/hangingLight.jpg"
import petbedchair from "./images/puppybed.jpg"
import bambooCaneSofa from "./images/bambooCaneSofa.jpg"
import Bamboo6seater from "./images/Bamboo6seater.jpg"
import roundbasket from "./images/roundbasket.jpg"
import hangingChair from "./images/hangingChair.jpg"
import rockingChair from "./images/rockingChair.jpg"
import bambooAmplifier from "./images/bambooAmplifier.jpg"
import babydollStroller from "./images/babydollStroller.jpg"
import kidChairs from "./images/kidChairs.jpg"
import storageBox from "./images/storageBox.jpg"
import servingTray from "./images/tray.jpg"
import tray1 from "./images/tray1.jpg"
import tray2 from "./images/tray2.jpg"
import tray3 from "./images/tray3.jpg"
import tray4 from "./images/tray4.jpg"
import tray5 from "./images/tray5.jpg"
import tray6 from "./images/tray6.jpg"
import tray7 from "./images/tray7.jpg"
import tray8 from "./images/tray8.jpg"
import tray9 from "./images/tray9.jpg"
import tray10 from "./images/tray10.jpg"
import tray11 from "./images/tray11.jpg"
import tray12 from "./images/tray12.jpg"
import tray13 from "./images/tray13.jpg"
import tray14 from "./images/tray14.jpeg"
import tray15 from "./images/tray15.jpeg"
import stool1 from "./images/stool1.jpg"
import stool2 from "./images/stool2.jpg"
import stool3 from "./images/stool3.jpg"
import stool4 from "./images/stool4.jpg"
import stool5 from "./images/stool5.jpg"
import stool6 from "./images/stool6.jpg"
import stool7 from "./images/stool7.jpg"
import stool8 from "./images/stool8.jpg"
import stool9 from "./images/stool9.jpg"
import stool10 from "./images/stool10.jpg"
import stool11 from "./images/stool11.jpg"
import stool12 from "./images/stool12.jpg"
import stool13 from "./images/stool13.jpg"
import stool14 from "./images/stool14.jpg"
import stool15 from "./images/stool15.jpg"
import stool16 from "./images/stool16.jpg"
import stool17 from "./images/stool17.jpg"
import stool18 from "./images/stool18.jpg"
import hangingChair1 from "./images/hangingChair1.jpg"
import hangingChair2 from "./images/hangingChair2.jpg"
import hangingChair3 from "./images/hangingChair3.jpg"
import hangingChair4 from "./images/hanginChair4.jpg"
import hangingChair5 from "./images/hangingChair5.jpg"
import hangingChair6 from "./images/hangingChair6.jpg"
import basket1 from "./images/basket1.jpg"
import basket2 from "./images/basket2.jpg"
import basket3 from "./images/basket3.jpg"
import basket4 from "./images/basket4.jpg"
import basket5 from "./images/basket5.jpg"
import basket6 from "./images/basket6.jpg"
import basket7 from "./images/basket7.jpg"
import basket8 from "./images/basket8.jpg"
import basket9 from "./images/basket9.jpg"
import basket10 from "./images/basket10.jpg"
import basket11 from "./images/basket11.jpg"
import basket12 from "./images/basket12.jpg"
import basket13 from "./images/basket13.jpg"
import basket14 from "./images/basket14.jpg"
import basket15 from "./images/basket15.jpg"
import basket16 from "./images/basket16.jpg"
import babydollStroller1 from "./images/babydollStroller1.jpg"
import babydollStroller2 from "./images/babydollStroller2.jpg"
import babydollStroller3 from "./images/babydollStroller3.jpg"
import babydollStroller4 from "./images/babydollStroller4.jpg"
import babydollStroller5 from "./images/babydollStroller5.jpg"
import babydollStroller6 from "./images/babydollStroller6.jpg"
import babydollStroller7 from "./images/babydollStroller7.jpg"
import babydollCrib1 from "./images/babydollCrib1.jpg"
import babydollCrib2 from "./images/babydollCrib2.jpg"
import babydollCrib3 from "./images/babydollCrib3.jpg"
import babydollCrib4 from "./images/babydollCrib4.jpg"
import babydollCrib5 from "./images/babydollCrib5.jpg"
import babydollCrib6 from "./images/babydollCrib6.jpg"
import babydollCrib7 from "./images/babydollCrib7.jpg"
import babydollCribA from "./images/babydollCribA.jpg"
import babydollCribB from "./images/babydollCribB.jpg"
import storageBox1 from "./images/storageBox1.jpg"
import storageBox2 from "./images/storageBox2.jpg"
import storageBox3 from "./images/storageBox3.jpg"
import storageBox4 from "./images/storageBox4.jpg"
import storageBox5 from "./images/storageBox5.jpg"
import bambooAmplifier1 from "./images/bambooAmplifier1.jpg"
import bambooAmplifier2 from "./images/bambooAmplifier2.jpg"
import bambooAmplifier3 from "./images/bambooAmplifier3.jpg"
import bambooAmplifier4 from "./images/bambooAmplifier4.jpg"
import bambooAmplifier5 from "./images/bambooAmplifier5.jpg"
import bambooAmplifier6 from "./images/bambooAmplifier6.jpg"
import bambooAmplifier7 from "./images/bambooAmplifier7.jpg"
import bambooAmplifier8 from "./images/bambooAmplifier8.jpg"
import bambooAmplifier9 from "./images/bambooAmplifier9.jpg"
import bambooAmplifier10 from "./images/bambooAmplifier10.jpg"
import sofaSet1 from "./images/sofaSet1.jpg"
import sofaSet2 from "./images/sofaSet2.jpg"
import sofaSet3 from "./images/sofaSet3.jpg"
import sofaSet4 from "./images/sofaSet4.jpg"
import sofaSet5 from "./images/sofaSet5.jpg"
import bambooDiningset1 from "./images/bambooDiningset1.jpg"
import bambooDiningset2 from "./images/bambooDiningset2.jpg"
import bambooDiningset3 from "./images/bambooDiningset3.jpg"
import bambooDiningset4 from "./images/bambooDiningset4.jpg"
import bambooDiningset5 from "./images/bambooDiningset5.jpg"
import bambooDiningset6 from "./images/bambooDiningset6.jpg"
import bambooDiningset7 from "./images/bambooDiningset7.jpg"
import bambooDiningset8 from "./images/bambooDiningSet8.jpg"
import bambooDiningset9 from "./images/bambooDiningSet9.jpg"
import bambooDiningset10 from "./images/bambooDiningSet10.jpg"
import bambooDiningset11 from "./images/bambooDiningset11.jpg"
import bambooDiningset12 from "./images/bambooDiningset12.jpg"
import bambooDiningset13 from "./images/bambooDiningset13.jpg"
import bambooDiningset14 from "./images/bambooDiningset14.jpg"
import bambooDiningset15 from "./images/bambooDiningset15.jpg"
import bambooDiningSet16 from "./images/bambooDiningSet16.jpg"
import newborn1 from "./images/newborn1.jpg"
import newborn2 from "./images/newborn2.jpg"
import newborn3 from "./images/newborn3.jpg"
import newborn4 from "./images/newborn4.jpg"
import newborn5 from "./images/newborn5.jpg"
import newborn6 from "./images/newborn6.jpg"
import newborn7 from "./images/newborn7.jpg"
import newborn8 from "./images/newborn8.jpg"
import newborn9 from "./images/newborn9.jpg"
import newborn10 from "./images/newborn10.jpg"
import newborn11 from "./images/newborn11.jpg"
import newborn12 from "./images/newborn12.jpg"
import newborn13 from "./images/newborn13.jpg"
import newborn14 from "./images/newborn14.jpg"
import newborn15 from "./images/newborn15.jpg"
import newborn16 from "./images/newborn16.jpg"
import newborn17 from "./images/newborn17.jpg"
import petBed1 from "./images/petBed1.jpg"
import petBed2 from "./images/petBed2.jpg"
import petBed3 from "./images/petBed3.jpg"
import petBed4 from "./images/petBed4.jpg"
import petBed5 from "./images/petBed5.jpg"
import petBed6 from "./images/petBed6.jpg"
import petBed7 from "./images/petBed7.jpg"
import petBed8 from "./images/petBed8.jpg"
import petBed9 from "./images/petBed9.jpg"
import petBed10 from "./images/petBed10.jpg"
import petBed11 from "./images/petBed11.jpg"
import petBed12 from "./images/petBed12.jpg"
import petBed13 from "./images/petBed13.jpg"
import petBed14 from "./images/petBed14.jpg"
import petBed15 from "./images/petBed15.jpg"
import petBed16 from "./images/petBed16.jpg"
import petBed17 from "./images/petBed17.jpg"
import petBed18 from "./images/petBed18.jpg"
import petBed19 from "./images/petBed19.jpg"
import sunLounger1 from "./images/sunLounger1.jpg"
import sunLounger2 from "./images/sunLounger2.jpg"
import sunLounger3 from "./images/sunLounger3.jpg"
import sunLounger4 from "./images/sunLounger4.jpg"
import sunLounger5 from "./images/sunLounger5.jpg"
import sunLounger6 from "./images/sunLounger6.jpg"
import sunLounger7 from "./images/sunLounger7.jpg"
import sunLounger8 from "./images/sunLounger8.jpg"
import sunLounger9 from "./images/sunLounger9.jpg"
import sunLounger10 from "./images/sunLounger10.jpg"
import sunLounger11 from "./images/sunLounger11.jpg"
import sunLounger12 from "./images/sunLounger12.jpg"
import sunLounger13 from "./images/sunLounger13.jpg"
import sunLounger14 from "./images/sunLounger14.jpg"
import sunLounger15 from "./images/sunLounger15.jpg"
import sunLounger16 from "./images/sunLounger16.jpg"
import sunLounger17 from "./images/sunLounger17.jpg"
import sunLounger18 from "./images/sunLounger18.jpg"
import kidChair1 from "./images/kidChair1.jpg"
import kidChair2 from "./images/kidChair2.jpg"
import kidChair3 from "./images/kidChair3.jpg"
import kidChair4 from "./images/kidChair4.jpg"
import kidChair5 from "./images/kidChair5.jpg"
import kidChair6 from "./images/kidChair6.jpg"
import kidChair7 from "./images/kidChair7.jpg"
import kidChair8 from "./images/kidChair8.jpg"
import kidChair9 from "./images/kidChair9.jpg"
import kidChair10 from "./images/kidChair10.jpg"
import kidChair11 from "./images/kidChair11.jpg"
import kidChair12 from "./images/kidChair12.jpg"
import kidChair13 from "./images/kidChair13.jpg"
import kidChair14 from "./images/kidChair14.jpg"
import kidChair15 from "./images/kidChair15.jpg"
import kidChair16 from "./images/kidChair16.jpg"
import kidChair17 from "./images/kidChair17.jpg"
import kidChair18 from "./images/kidChair18.jpg"
import kidChair19 from "./images/kidChair19.jpg"
import kidChair20 from "./images/kidChair20.jpg"
import kidChair21 from "./images/kidChair21.jpg"
import kidChair22 from "./images/kidChair22.jpg"
import kidChair23 from "./images/kidChair23.jpg"
import kidChair24 from "./images/kidChair24.jpg"
import kidChair25 from "./images/kidChair25.jpg"
import kidChair26 from "./images/kidChair26.jpg"
import kidChair27 from "./images/kidChair27.jpg"
import kidChair28 from "./images/kidChair28.jpg"
import lamp1 from "./images/lamp1.jpg"
import lamp2 from "./images/lamp2.jpeg"
import lamp3 from "./images/lamp3.jpg"
import lamp4 from "./images/lamp4.jpg"
import lamp5 from "./images/lamp5.jpg"
import lamp6 from "./images/lamp6.jpg"
import lamp7 from "./images/lamp7.jpg"
import lamp8 from "./images/lamp8.jpg"
import lamp9 from "./images/lamp9.jpg"
import lamp10 from "./images/lamp10.jpg"
import lamp11 from "./images/lamp11.jpg"
import lamp12 from "./images/lamp12.jpg"
import lamp13 from "./images/lamp13.jpg"
import lamp14 from "./images/lamp14.jpg"
import lamp15 from "./images/lamp15.jpg"
import lamp16 from "./images/lamp16.jpg"
import lamp17 from "./images/lamp17.jpg"
import lamp18 from "./images/lamp18.jpg"
import lamp19 from "./images/lamp19.jpg"
import lamp20 from "./images/lamp20.jpg"
import lamp21 from "./images/lamp21.jpg"
import lamp22 from "./images/lamp22.jpg"
import lamp23 from "./images/lamp23.jpg"
import lamp24 from "./images/lamp24.jpg"
import lamp25 from "./images/lamp25.jpg"
import lamp26 from "./images/lamp26.jpg"
import lamp27 from "./images/lamp27.jpg"
import lamp28 from "./images/lamp28.jpg"
import lamp29 from "./images/lamp29.jpg"
import lamp30 from "./images/lamp30.jpg"
import lamp31 from "./images/lamp31.jpg"
import lamp32 from "./images/lamp32.jpg"
import lamp33 from "./images/lamp33.jpg"
import lamp34 from "./images/lamp34.jpg"
import lamp35 from "./images/lamp35.jpeg"
import lamp36 from "./images/lamp36.jpg"
import lamp37 from "./images/lamp37.jpeg"
import lamp38 from "./images/lamp38.jpg"
import sofaSet6 from "./images/sofaSet6.jpg"
import sofaSet7 from "./images/sofaSet7.jpg"
import sofaSet8 from "./images/sofaSet8.jpg"
import sofaSet9 from "./images/sofaSet9.jpg"
import sofaSet10 from "./images/sofaSet10.jpg"
import sofaSet11 from "./images/sofaSet11.jpg"
import sofaSet12 from "./images/sofaSet12.jpg"
import storageBox6 from "./images/storageBox6.jpg"
import shelve1 from "./images/shelve1.jpg"
import shelve2 from "./images/shelve2.jpg"
import shelve3 from "./images/shelve3.jpg"
import shelve4 from "./images/shelve4.jpg"
import shelve5 from "./images/shelve5.jpg"
import shelve6 from "./images/shelve6.jpg"
import shelve7 from "./images/shelve7.jpg"
import shelve8 from "./images/shelve8.jpg"
import shelve9 from "./images/shelve9.jpg"
import shelve10 from "./images/shelve10.jpg"
import shelve11 from "./images/shelve11.jpg"
import shelve12 from "./images/shelve12.jpg"
import shelve13 from "./images/shelve13.jpg"
import shelve14 from "./images/shelve14.jpg"
import shelve15 from "./images/shelve15.jpg"
import shelve16 from "./images/shelve16.jpg"
import shelve17 from "./images/shelve17.jpg"
import shelve18 from "./images/shelve18.jpg"
import shelve19 from "./images/shelve19.jpg"
import table1 from "./images/table1.jpg"
import table2 from "./images/table2.jpg"
import table3 from "./images/table3.jpg"
import table4 from "./images/table4.jpg"
import table5 from "./images/table5.jpg"
import table6 from "./images/table6.jpg"
import table7 from "./images/table7.jpg"
import table8 from "./images/table8.jpg"
import table9 from "./images/table9.jpg"
import table10 from "./images/table10.jpg"
import table11 from "./images/table11.jpg"
import table12 from "./images/table12.jpg"
import table13 from "./images/table13.jpg"
import table14 from "./images/table14.jpg"
import table15 from "./images/table15.jpg"
import table16 from "./images/table16.jpg"
import table17 from "./images/table17.jpg"
import table18 from "./images/table18.jpg"
import table19 from "./images/table19.jpg"
import table20 from "./images/table20.jpg"
import table21 from "./images/table21.jpg"
import table22 from "./images/table22.jpg"
import table23 from "./images/table23.jpg"
import table24 from "./images/table24.jpg"
import table25 from "./images/table25.jpg"
import table26 from "./images/table26.jpg"
import stoolTable1 from "./images/stoolTable1.jpg"
import stoolTable2 from "./images/stoolTable2.jpg"
import stoolTable3 from "./images/stoolTable3.jpg"
import stoolTable4 from "./images/stoolTable4.jpg"
import stoolTable5 from "./images/stoolTable5.jpg"
import stoolTable6 from "./images/stoolTable6.jpg"
import stoolTable7 from "./images/stoolTable7.jpg"
import stoolTable8 from "./images/stoolTable8.jpg"
import stoolTable9 from "./images/stoolTable9.jpg"
import stoolTable10 from "./images/stoolTable10.jpg"
import stoolTable11 from "./images/stoolTable11.jpg"
import stoolTable12 from "./images/stoolTable12.jpg"
import chair1 from "./images/chair1.jpg"
import chair2 from "./images/chair2.jpg"
import chair3 from "./images/chair3.jpg"
import chair4 from "./images/chair4.jpg"
import chair5 from "./images/chair5.jpg"
import chair6 from "./images/chair6.jpg"
import chair7 from "./images/chair7.jpg"
import chair8 from "./images/chair8.jpg"
import chair9 from "./images/chair9.jpg"
import chair10 from "./images/chair10.jpg"
import chair11 from "./images/chair11.jpg"
import chair12 from "./images/chair12.jpg"
import chair13 from "./images/chair13.jpg"
import chair14 from "./images/chair14.jpg"
import chair15 from "./images/chair15.jpg"
import chair16 from "./images/chair16.jpg"
import chair17 from "./images/chair17.jpg"
import chair18 from "./images/chair18.jpg"
import chair19 from "./images/chair19.jpg"
import chair20 from "./images/chair20.jpg"
import chair21 from "./images/chair21.jpg"
import chair22 from "./images/chair22.jpg"
import chair23 from "./images/chair23.jpg"
import chair24 from "./images/chair24.jpg"
import chair25 from "./images/chair25.jpg"
import chair26 from "./images/chair26.jpg"
import chair27 from "./images/chair27.jpg"
import chair28 from "./images/chair28.jpg"
import chair29 from "./images/chair29.jpg"
import chair30 from "./images/chair30.jpg"
import chair31 from "./images/chair31.jpg"
import chair32 from "./images/chair32.jpg"
import chair33 from "./images/chair33.jpg"
import chair34 from "./images/chair34.jpg"
import chair35 from "./images/chair35.jpg"
import chair36 from "./images/chair36.jpg"
import chair37 from "./images/chair37.jpg"
import chair38 from "./images/chair38.jpg"
import chair39 from "./images/chair39.jpg"
import hangingChair8 from "./images/hangingChair8.jpg"
import hangingChair9 from "./images/hangingChair9.jpg"
import hangingChair10 from "./images/hangingChair10.jpg"
import hangingChair11 from "./images/hangingChair11.jpg"
import hangingChair12 from "./images/hangingChair12.jpg"
import hangingChair13 from "./images/hangingChair13.jpg"
import hangingChair14 from "./images/hangingChair14.jpg"
import hangingChair15 from "./images/hangingChair15.jpg"
import hangingChair16 from "./images/hangingChair16.jpg"
import rockingChair1 from "./images/rockingChair1.jpg"
import rockingChair2 from "./images/rockingChair2.jpg"
import rockingChair3 from "./images/rockingChair3.jpg"
import rockingChair4 from "./images/rockingChair4.jpeg"
import rockingChair5 from "./images/rockingChair5.jpg"
import rockingChair6 from "./images/rockingChair6.jpg"
import rockingChair7 from "./images/rockingChair7.jpg"
import rockingChair8 from "./images/rockingChair8.jpg"
import rockingChair9 from "./images/rockingChair9.jpg"
import rockingChair10 from "./images/rockingChair10.jpg"
import rockingChair11 from "./images/rockingChair11.jpg"
import rockingChair12 from "./images/rockingChair12.jpg"
import rockingChair13 from "./images/rockingChair13.jpg"
import rockingChair14 from "./images/rockingChair14.jpg"
import rockingChair15 from "./images/rockingChair15.jpg"
import storageBox8 from "./images/storageBox8.jpg"
import storageBox9 from "./images/storageBox9.jpg"
import storageBox10 from "./images/storageBox10.jpg"
import storageBox11 from "./images/storageBox11.jpg"
import storageBox12 from "./images/storageBox12.jpg"
import storageBox13 from "./images/storageBox13.jpg"
import storageBox14 from "./images/storageBox14.jpg"
import petBed20 from "./images/petBed20.jpg"
import mudhaSet1 from "./images/mudhaSet1.jpg"
import mudhaSet2 from "./images/mudhaSet2.jpg"
import mudhaSet3 from "./images/mudhaSet3.jpg"
import mudhaSet4 from "./images/mudhaSet4.jpg"
import mudhaSet5 from "./images/mudhaSet5.jpg"
import mudhaSet6 from "./images/mudhaSet6.jpg"
import mudhaSet7 from "./images/mudhaSet7.jpg"
import mudhaSet8 from "./images/mudhaSet8.jpg"
import mudhaSet9 from "./images/mudhaSet9.jpg"
import mudhaSetl0 from "./images/mudhaSet10.jpg"
import mudhaSetl1 from "./images/mudhaSet11.jpg"
import mudhaSetl2 from "./images/mudhaSet12.jpg"
import mudhaSetl3 from "./images/mudhaSet13.jpg"
import mudhaSetl4 from "./images/mudhaSet14.jpg"
import mudhaSetl5 from "./images/mudhaSet15.jpg"
import bench1 from "./images/bench1.jpg"
import bench2 from "./images/bench2.jpg"
import bench3 from "./images/bench3.jpg"
import charpai1 from "./images/charpai1.jpg"
import charpai2 from "./images/charpai2.jpg"
import charpai3 from "./images/charpai3.jpg"
import charpai4 from "./images/charpai4.jpg"
import charpai5 from "./images/charpai5.jpg"
import charpai6 from "./images/charpai6.jpg"
import charpai7 from "./images/charpai7.jpg"
import charpai8 from "./images/charpai8.jpg"
import charpai9 from "./images/charpai9.jpg"
import charpai10 from "./images/charpai10.jpg"
import charpai11 from "./images/charpai11.jpg"
import charpai12 from "./images/charpai12.jpg"
import charpai13 from "./images/charpai13.jpg"
import charpai14 from "./images/charpai14.jpg"
import peedi1 from "./images/peedi1.jpg"
import peedi2 from "./images/peedi2.jpg"
import peedi3 from "./images/peedi3.jpg"
import peedi4 from "./images/peedi4.jpg"












const data = [
    {
      id: "recZkNf2kwmdBcqd01234",
      name: "Newborn Photography Prop Cradle",
      price: 25,
      lastPrice :35,
      image:
      newbornBlackCradle,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 29,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our Newborn Photography Prop Cradle is a stunning, handcrafted piece of furniture that is perfect for both photo shoots and nurseries. Made from natural bamboo, sarkanda, wicker, and cane materials, this cradle is not only beautiful but also eco-friendly. The soft cushioning provides a comfortable and safe place for newborns to rest during their first few weeks of life, and it's the perfect prop for capturing those precious early memories.       The unique design of our cradle adds a touch of natural charm to any photography session or nursery decor. Whether you're a professional photographer or a new parent, this cradle will make a beautiful addition to your collection of baby props. It is sturdy and durable, so you can be sure it will last for years to come  We take pride in our commitment to sustainability, and our Newborn Photography Prop Cradle is a testament to that commitment. By using natural materials, we are able to create beautiful, functional furniture without harming the environment. When you purchase our cradle, you can feel good knowing that you're making a responsible choice for your family and for the planet.      Invest in this timeless and sustainable piece, and capture memories that will last a lifetime. Order your Newborn Photography Prop Cradle today and elevate your photography game!",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recEHmzvupvT8ZONH",
      name: "Newborn Photography Prop Basket",
      price: 17,
      lastPrice :24,
      image:
      newBornBasket,
      colors: ["#000", "#ffb900"],
      company: "Ecowoodies",
      reviews: 19,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our Newborn Photography Prop Basket is a beautiful and versatile prop that is perfect for capturing those precious early memories. Handcrafted from natural bamboo, sarkanda, wicker, and cane materials, this basket is not only stunning but also eco-friendly. With its soft cushioning, the basket provides a comfortable and safe place for newborns to rest during photo shoots. Its unique design adds a touch of natural charm to any photography session or nursery decor. You can use it to create beautiful, whimsical scenes or classic, timeless portraits.     At our furniture manufacturing company, we pride ourselves on our commitment to sustainability. Our Newborn Photography Prop Basket is made from natural materials and crafted by hand, ensuring that each piece is unique and special. When you choose our basket, you're making a responsible choice for your family and for the planet. Invest in this beautiful and eco-friendly prop, and elevate your photography game. Order your Newborn Photography Prop Basket today and capture memories that will last a lifetime.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "rec5NBwZ5zCD9nfF0",
      name: "Newborn Photography Prop Rattan Cradle",
      price: 24,
      lastPrice :35,
      image:
      newBornPinkCradel,
      colors: ["#ffb900", "#0000ff"],
      company: "Ecowoodies",
      reviews: 12,
      featured: true,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our Red Newborn Photography Prop Cradle is a stunning addition to any photography studio or nursery. Handcrafted from natural bamboo, sarkanda, wicker, and cane materials, this cradle is not only beautiful but also eco-friendly. Its soft cushioning provides a comfortable and safe place for newborns to rest during photo shoots. The red color of the cradle adds a pop of boldness and fun to your photography sessions, making it a versatile prop that can be used for both classic and modern portraits. Its unique design adds a touch of natural charm to any photography session or nursery decor, making it a must-have for any new parent or photographer. We take pride in our commitment to sustainability, and our Red Newborn Photography Prop Cradle is a testament to that commitment. By using natural materials, we're able to create beautiful, functional furniture without harming the environment. When you purchase our cradle, you can feel good knowing that you're making a responsible choice for your family and for the planet. Invest in this timeless and sustainable piece, and capture memories that will last a lifetime. Order your Red Newborn Photography Prop Cradle today and elevate your photography game with a bold and stunning pop of color.",
      category: "NewBorns",
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Bamboo Lounge Chair",
      price: 38,
      lastPrice :52,
      image: bamboCategory,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 19,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        "Experience the ultimate in comfort and style with our handcrafted Bamboo Lounge Chair, made in the North East States of India. This chair is the perfect addition to any home, whether you're looking for a cozy reading nook or a relaxing spot to watch TV. Crafted from natural and sustainable bamboo materials, this lounge chair is not only beautiful but also eco-friendly. Its unique design provides optimal comfort, with a sturdy frame and soft cushions that cradle your body. At our furniture manufacturing company, we take pride in our commitment to sustainability and traditional craftsmanship. Each Bamboo Lounge Chair is meticulously handcrafted, ensuring that every piece is unique and of the highest quality. With its timeless design and natural materials, our Bamboo Lounge Chair is a versatile piece that can complement any decor. Whether you're going for a modern, minimalist look or a cozy, rustic feel, this chair is the perfect fit. Invest in sustainable and stylish furniture with our Bamboo Lounge Chair. Order yours today and add a touch of natural beauty to your home.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Baby Chair",
      price: 20,
      lastPrice :28,
      image:
      babyChair,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 65,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Chair for Photography",
      description:
        "Introducing our Sarkanda Baby Chair, handcrafted with love by local artisans of North India. Made from sustainable sarkanda materials, this chair is the perfect addition to your baby's nursery or any other cozy corner of your home. Our furniture manufacturing company takes pride in our commitment to sustainability and traditional craftsmanship. Each Sarkanda Baby Chair is handmade by local artisans, ensuring that every piece is unique and of the highest quality. Designed with both comfort and safety in mind, this chair provides optimal support for your baby while still being stylish and functional. Its natural materials and simple design complement any nursery decor, adding a touch of natural beauty to your home. Sarkanda is a durable and eco-friendly material that has been used for centuries to make furniture and other household items. By using sustainable materials, we're able to create beautiful, functional furniture without harming the environment. Invest in sustainable and stylish furniture with our Sarkanda Baby Chair. Order yours today and give your baby a safe and comfortable place to rest and play.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Hanging Light",
      price: 15,
      lastPrice :21,
      image:
      hangingLight,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 107,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Illuminate your space with our handcrafted Bamboo Hanging Light, made by skilled artisans from the North East States of India. Crafted from natural and sustainable bamboo materials, this light fixture adds a touch of warmth and elegance to any room in your home. Our furniture manufacturing company takes pride in our commitment to sustainability and traditional craftsmanship. Each Bamboo Hanging Light is meticulously handmade, ensuring that every piece is unique and of the highest quality. With its simple yet stylish design, this hanging light is the perfect addition to any home decor. The natural materials and intricate weaving techniques used in its creation give it a distinct character that sets it apart from other lighting options. Not only is bamboo a beautiful and durable material, but it's also an eco-friendly choice that helps reduce your carbon footprint. By choosing sustainable materials, you can make a positive impact on the environment while still enjoying beautiful and functional furniture. Invest in sustainable and stylish lighting with our Bamboo Hanging Light. Order yours today and add a touch of natural beauty to your home.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "rec1Ntk7siEEW9ha1",
      name: "Bamboo 5 Seater Sofa",
      price: 125,
      lastPrice :145,
      image:
      bambooSofa,
      colors: ["#0000ff", "#000"],
      company: "Ecowoodies",
      reviews: 35,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      description:
        "Experience the beauty of natural and sustainable materials with our handcrafted Bamboo 5 Seater Sofa Set, made by skilled artisans from the North East States of India. This elegant and durable furniture set is the perfect addition to your living room, adding both comfort and style to your home. Our furniture manufacturing company takes pride in our commitment to sustainability and traditional craftsmanship. Each piece in this sofa set is handmade with meticulous attention to detail, ensuring that every sofa is unique and of the highest quality. The bamboo used in this sofa set is a sustainable and eco-friendly material that's also known for its strength and durability. With proper care, your bamboo sofa set can last for years, making it a practical and cost-effective choice for your home. Not only is this sofa set beautiful and functional, but it also adds a touch of natural beauty to your living space. The natural grain and texture of the bamboo gives this set a unique character that sets it apart from other furniture options. Invest in sustainable and stylish furniture with our Bamboo 5 Seater Sofa Set. Order yours today and experience the natural beauty and durability of bamboo.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Cane 5 Seater Sofa",
      price: 110,
      lastPrice :135,
      image:
       sofaCategory,
  
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 25,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "Transform your living room with our handcrafted Cane 5 Seater Sofa Set, made with care by local artisans in North India. The set's unique design and durable construction are sure to impress, while its eco-friendly materials and ethical production practices make it a responsible choice for your home. Crafted from high-quality cane, this sofa set is strong and built to last. The natural texture and warm color of the cane lend it a timeless elegance that complements any decor style. Plus, because it's handmade, each sofa is one-of-a-kind, adding a touch of artistry to your living space. As a furniture manufacturing company that values sustainability, we're committed to using only responsibly sourced materials and ethical production practices. We partner with local artisans to ensure that our furniture is made with care and attention to detail, while also supporting the livelihoods of skilled craftspeople. The Cane 5 Seater Sofa Set is perfect for families and socializing, with plenty of room for everyone to relax in comfort. Its clean lines and simple design allow it to blend seamlessly with your existing decor, while its sturdy construction ensures it will stand the test of time. Upgrade your living room with our stylish and sustainable Cane 5 Seater Sofa Set. Order yours today and experience the beauty and quality of handmade furniture from North India. ",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recrfxv3EwpvJwvjq",
      name: "Bakery Tray",
      price: 7,
      lastPrice :12,
      image:
      tray,
  
      colors: ["#000", "#00ff00"],
      company: "Ecowoodies",
      reviews: 27,
      featured: true,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"EU Food Safety Certified",
      feature6:"Bakey & Dining",
      description:
        "Introducing our exquisite handmade Cane Bakery Tray, crafted with utmost care and attention by skilled local artisans from North India. Made with high-quality cane material, this tray exudes elegance, style, and durability, making it a perfect addition to your bakery or kitchen. The beautiful natural texture of the cane adds an element of warmth and sophistication to any setting, making it a great conversation starter among your guests. Its lightweight and sturdy design make it easy to carry around, while its large size makes it ideal for serving and displaying your delectable baked goods, pastries, and desserts. Our commitment to sustainable and eco-friendly practices is reflected in the use of cane, a renewable and biodegradable material that is gentle on the environment. Whether you're a professional baker or a home cook, this Cane Bakery Tray is a must-have for anyone who values style, functionality, and quality. Shop now and experience the beauty of handmade craftsmanship at its best.",
      category: "office",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Ottoman",
      price: 75,
      lastPrice :83,
      image:
        stoolCategory,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 230,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels, Home and Cafe",
      description:
        "Introducing our stunning collection of Cane Ottomans, crafted by local artisans of North India using eco-friendly and recyclable materials. Each ottoman is unique and handmade, adding a touch of natural beauty to any living space. Our Cane Ottomans are the perfect addition to your home decor, providing a stylish and sustainable seating option. Made from high-quality cane, they are durable and sturdy, ensuring long-lasting use. The natural texture and color of the cane add a warm and cozy feeling to your living space. With its versatile design, our Cane Ottomans can be used as a footrest, extra seating or even as a small table. Its lightweight and compact design make it easy to move around and store when not in use. We take pride in our commitment to sustainability, and each of our Cane Ottomans is crafted using eco-friendly and recyclable materials. By choosing our handmade products, you are not only adding a unique and stylish piece to your home decor, but also contributing to a greener and more sustainable future. Add a touch of elegance and sustainability to your home with our beautiful collection of Cane Ottomans. Choose from our variety of sizes and designs to find the perfect fit for your home.",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Pet Bed",
      price: 26,
      lastPrice :35,
      image:
      petbedchair,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 230,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "If you're looking for a cozy and eco-friendly bed for your furry friend, our handmade pet beds are just what you need. Crafted by skilled artisans in North India, these beds are not only stylish but also sustainable as they are made from natural and renewable bamboo materials. Our pet beds come in a variety of sizes and designs to fit your pet's unique needs and personality. The soft cushioning ensures maximum comfort and support for your pet, allowing them to rest peacefully after a long day of playing and exploring. Not only are these beds great for your pet, but they are also great for the environment. Bamboo is one of the most sustainable materials available, and our commitment to eco-friendliness means that we use only eco-friendly, recyclable materials in our products. Investing in our pet beds means that you're not only getting a high-quality product but also contributing to a better future for our planet. So give your pet the gift of comfort and sustainability with our handmade bamboo pet beds.",
      category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Cane Sofa",
      price: 155,
      lastPrice :185,
      image:
      bambooCaneSofa,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 30,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotel, Restaurants",
      description:
        "Introducing our exquisite Bamboo Cane Sofa, a true masterpiece that blends natural elegance with modern design. Handmade by skilled artisans in the serene hills of Assam, Tripura, and Nagaland, each sofa is crafted using the finest quality bamboo cane. Our sustainable and eco-friendly production process ensures that every sofa is not only stunning, but also fully recyclable.  Our Bamboo Cane Sofa is the perfect addition to any home, office or outdoor space, providing a comfortable and stylish seating option that will make a statement. The unique design incorporates traditional weaving techniques with contemporary shapes, creating a versatile and timeless piece that will impress for years to come. We take pride in our commitment to supporting local communities and preserving traditional crafts. By purchasing our Bamboo Cane Sofa, you are not only making an environmentally conscious choice, but also supporting the livelihoods of skilled artisans who have honed their craft for generations. xperience the natural beauty and exceptional quality of our Bamboo Cane Sofa, and add a touch of elegance to your space that will be admired for its beauty and admired for its eco-friendliness.",
      category: "BambooSofas",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Patio 6 Seater Dining Set",
      price: 228,
      lastPrice :278,
      image:
      Bamboo6seater,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 28,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotel, Restaurants, Garden, Dining Room",
      description:
        " If you are looking for a high-quality dining set that is as beautiful as it is sustainable, look no further than our Bamboo six sitting dining table set. With its handmade craftsmanship, eco-friendly materials, and timeless design, it is the perfect choice for anyone who values both style and sustainability.",
      category: "Bamboodiningsets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Round Baskets",
      price: 12,
      lastPrice :19,
      image:
      roundbasket,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 25,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Bread Fruits Flower Storage,Indoor Outdoor Carrying Basket",
      description:
        " These baskets are perfect for any room in your home, from the living room to the bathroom. They can be used for storage, decoration, or even as a planter for your favorite indoor plants. And because they are made from rattan, they are strong, durable and built to last.Each basket is carefully woven by hand, ensuring the highest quality and attention to detail.",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Stylish White Hanging Chair",
      price: 90,
      lastPrice :117,
      image:
      hangingChair,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " Perfect for any room in your home, our hanging chairs provide a cozy and relaxing seating option that will add a touch of natural beauty to your decor. They are great for indoor or outdoor use and can be easily hung from a sturdy beam or ceiling hook.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
     {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rocking Chair",
      price:45,
      lastPrice :55,
      image:
      rockingChair,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  Bamboo cane rocking chairs are great for outdoor living spaces such as patios, decks, or gardens. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Sound Amplifier",
      price: 12,
      lastPrice :15,
      image:
      bambooAmplifier,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Parties Events, Hotel, Restaurant",
      description:
        "  Perfect for any music lover, our bamboo amplifiers are compatible with most smartphones and music players, providing a portable and convenient way to listen to your favorite tunes. They are great for indoor or outdoor use, and can be easily transported to your next outdoor adventure or picnic.",
      category: "Bambooamplifier",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Baby Doll Stroller",
      price: 42,
      lastPrice :57,
      image:
      babydollStroller,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 37,
      featured: true,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Playroom, Bedroom",
      description:
        "  Baby doll rattan stroller is a classic and timeless toy that can provide hours of imaginative play for children. Its lightweight, eco-friendly, and stylish design makes it a great choice for parents who want to encourage their children's creativity and sustainability. ",
      category: "Babydollstrollers",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Eco-freindly Rattan Kids Chair",
      price: 27,
      lastPrice :35,
      image:
      kidChairs,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Playroom, Studyroom, Bedroom, Hotel, School, Restaurant",
      description:
        "  Bamboo rattan kids chairs are lightweight and easy to move, making them perfect for children to use both indoors and outdoors. They are also durable and sturdy, ensuring they will last through years of play. ",
      category: "Kidchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Closet Designer Wicker Storage Boxes",
      price: 22,
      lastPrice :28,
      image:
      storageBox,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 28,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " Bamboo rattan large storage boxes have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. It can be used for a variety of purposes, including as a coffee table, storage ottoman, or as a decorative accent in any room of the house. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Serving Tray",
      price: 9,
      lastPrice :12,
      image:
      servingTray,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Bar, Pubs, Hotel, Restaurant",
      description:
        " Handwoven serving trays are lightweight made from natural rattan and seagrass, which are durable and sturdy enough to withstand daily use and easy to carry, making them perfect for serving food and drinks both indoors and outdoors. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Bamboo Serving Trays",
      price: 9,
      lastPrice :12,
      image:    tray1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 24,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Bamboo trays are eco-friendly and stylish home decor pieces that stand out for their uniqueness and quality.Bamboo trays are resistant to scratches, chips, and stains, and they can last for a long time with proper care. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Woven Tray",
      price: 9,
      lastPrice :12,
      image:
      tray2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 30,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Rattan trays are eco-friendly and stylish home decor pieces that stand out for their uniqueness and quality.These trays are resistant to scratches, chips, and stains, and they can last for a long time with proper care. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Food Trays",
      price: 21,
      lastPrice :28,
      image:
      tray3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Bamboo trays have a natural, earthy look that can add a touch of warmth and texture to any room. They come in various sizes and designs, from simple and minimalist to intricate and decorative, and can complement any decor style. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Rattan Tray",
      price: 8,
      lastPrice :12,
      image:
      tray4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 31,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Rattan trays are lightweight and easy to carry, making them ideal for serving food and drinks or organizing items on the go. They can be used in outdoor settings such as picnics, camping trips, or backyard parties. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Bamboo Tray",
      price: 8,
      lastPrice :11,
      image:
      tray5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 22,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our bamboo trays are durable and sturdy, but they also feature intricate designs that showcase the beauty and versatility of bamboo. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Set of 3 Handmade Bamboo Trays",
      price: 8,
      lastPrice :11,
      image:
      tray6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 32,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our bamboo trays are durable, sturdy, naturally resistant to bacteria and mold, making them a hygienic choice for food-related purposes. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Bamboo Tray",
      price: 10,
      lastPrice :14,
      image:
      tray7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our bamboo trays are a sustainable and stylish alternative to conventional trays, offering numerous benefits for both the environment and the user. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Bamboo Tray",
      price: 10,
      lastPrice :15,
      image:
      tray8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 33,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our bamboo trays are a sustainable and stylish and resistant to scratches, chips, and stains, and they can last for a long time with proper care. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Tea Tray",
      price: 16,
      lastPrice :22,
      image:
      tray9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 33,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our wooden designer trays are a sustainable and stylish and resistant to scratches, chips, and stains, and they can last for a long time with proper care. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rectangle Wooden Tray Set",
      price: 48,
      lastPrice :68,
      image:
      tray10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Our wooden trays offer durability, natural beauty, and eco-friendliness, making them a popular choice among homeowners and decorators alike. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Wooden Coffee Serving Table Tray ",
      price: 14,
      lastPrice :19,
      image:
      tray11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 44,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Wooden trays are commonly used for serving food and drinks. They are perfect for serving breakfast in bed, appetizers at parties, and even for carrying drinks from the kitchen to the living room. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rectangle Wooden Tray",
      price: 16,
      lastPrice :22,
      image:
      tray12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 34,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Wooden trays are an eco-friendly option as they are made from a renewable resource. They are also biodegradable and do not release harmful chemicals into the environment. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Oval Wooden Bamboo Tray",
      price: 15,
      lastPrice :21,
      image:
      tray13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 37,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Wooden trays come in a variety of colors and grains, each with its unique beauty. They can add warmth and charm to any kitchen or dining room and  do not release harmful chemicals into the environment. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Mini Stylish Bamboo Tray",
      price: 13,
      lastPrice :21,
      image:
      tray14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 47,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        " Designer bamboo wooden trays also make great decorative pieces. They can be used to display candles, flowers, or other decorative objects on a coffee table or shelf. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Beige 3 Compartment Tray",
      price: 13,
      lastPrice :20,
      image:
      tray15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 47,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Home, Bar, Pubs, Hotel, Restaurant",
      description:
        "  Bamboo compartment trays can be used for serving snacks, appetizers, and desserts. They are perfect for hosting parties or for a casual get-together with friends and family. ",
      category: "trays",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Set of 2 Handmade Bamboo Bar Sticks Stool",
      price: 32,
      lastPrice :39,
      image:
      stool1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 157,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vanity Dorm Bamboo Ottoman",
      price: 14,
      lastPrice :17,
      image:
      stool2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 105,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " Ottomans come in a variety of shapes, sizes, and designs, making them a great way to add a decorative touch to a living space. These are upholstered in different fabrics or patterns to complement the décor of a room. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Round Cane Decorative Stool Set",
      price: 38,
      lastPrice :50,
      image:
      stool3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 97,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " Cane stools are known for their durability and can last for many years with proper care. The unique texture and natural color of cane stools give them a rustic and organic aesthetic that complements a variety of interior design styles. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Round Leather Ottoman Pouf",
      price: 18,
      lastPrice :26,
      image:
      stool4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 135,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " Ecowoodies bamboo ottomans are a versatile and functional piece of furniture that can add both style and practicality to any living space. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Patio Rectangular Low Height Ottomans ",
      price: 41,
      lastPrice :52,
      image:
      stool5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 165,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Ecowoodies Bamboo stool sets can be used as seating options in different spaces such as living rooms, bedrooms, patios, and gardens. They come in different heights and sizes and can be arranged in different configurations to suit your needs. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Bamboo Stool With Cushion ",
      price: 16,
      lastPrice :22,
      image:
      stool6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 124,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Ecowoodies bamboo stool is uniquely designed, with its own individual character and charm, making it a true piece of art for your home. With its strong and durable construction, our Bamboo stools are perfect for both indoor and outdoor use, and can withstand the test of time. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Rattan Bar Stool",
      price: 19,
      lastPrice :28,
      image:
      stool7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 128,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Ecowoodies stools are lightweight and easy to move around, making them a practical choice for small spaces or outdoor settings. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Decorative Wicker Tea Table",
      price: 19,
      lastPrice :29,
      image:
      stool8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 138,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " The unique texture and natural color of cane give them a rustic and organic aesthetic that complements a variety of interior design styles. These are lightweight and easy to move around, making them a practical choice for small spaces or outdoor settings. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
   {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Wicker Round White Ottoman ",
      price: 19,
      lastPrice :28,
      image:
      stool9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 148,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " These ottomans can also serve as additional seating options for guests, especially in small spaces where there may not be enough room for a full-size chair. These are lightweight and easy to move around, making them a practical choice for small spaces or outdoor settings. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Cane Round Corner Stool",
      price: 20,
      lastPrice :29,
      image:
      stool10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 108,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " These Cane stools are known for their durability and can last for many years with proper care. The cane weaving provides a sturdy base that can withstand daily use. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Wicker Multicolor Iron Stool",
      price: 48,
      lastPrice :67,
      image:
      stool11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 128,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " These stools are made from natural materials that are renewable and biodegradable, making them an eco-friendly option for furniture. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Set of 2 Foot Stool Iron",
      price: 45,
      lastPrice :65,
      image:
      stool12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 128,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " These are lightweight and easy to move around, making them a practical choice for small spaces or outdoor settings and made from natural materials that are renewable and biodegradable, making them an eco-friendly option for furniture. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Eco Friendly Tuffted Round Ottoman",
      price: 20,
      lastPrice :27,
      image:
      stool13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 136,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Ottomans are often used as seating options as they offer a comfortable place to sit. The cane weaving provides a supportive, yet flexible surface that contours to the body. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Iron Cane Round Small Ottoman",
      price: 16,
      lastPrice : 20,
      image:
      stool14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 96,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " The primary use of an ottoman is as a footrest. It provides a comfortable place to rest your feet after a long day and can help improve circulation. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Rattan Low Seat Black and Beige Ottoman",
      price: 15,
      lastPrice : 22,
      image:
      stool15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 126,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " The primary use of an ottoman is as a footrest and can also serve as additional seating options for guests, especially in small spaces where there may not be enough room for a full-size chair. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Set of 2 Rattan Wicker Mid Century Round Ottomans",
      price: 35,
      lastPrice : 49,
      image:
      stool16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 146,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " These are set of 2 ottomans of different sizes can be used as footrest and also serve as additional seating options for guests, especially in small spaces where there may not be enough room for a full-size chair. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Rattan Vintage Rectangular Low Seat Ottomans",
      price: 48,
      lastPrice :62,
      image:
      stool17,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 116,
      featured: "Set of 4 Ottomans",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Low height bamboo ottomans can be used as footrest and also serve as additional seating options for guests, especially in small spaces where there may not be enough room for a full-size chair. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handmade Natural Color Round Bamboo Ottoman",
      price: 22,
      lastPrice : 32,
      image:
      stool18,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 156,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant, Coffee Home ",
      description:
        " Bamboo ottomans can be used as seating options in different spaces such as living rooms, bedrooms, patios, and gardens. They come in different heights and sizes and can be arranged in different configurations to suit your needs.",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Wicker Hanging Chair",
      price: 94,
      lastPrice : 119,
      image:
      hangingChair1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 26,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " The unique design of hanging chair allows for comfortable lounging and relaxation, while the hanging feature provides an elevated and elegant touch to any indoor or outdoor setting.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Rattan Hanging Chair",
      price: 86,
      lastPrice : 97,
      image:
      hangingChair2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 29,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " This handmade rattan hanging chair is  unique and stylish in  appearance that gives a natural and organic element to any room or outdoor space. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Rattan Hanging Swing Chair",
      price: 126,
      lastPrice : 156,
      image:
      hangingChair3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 27,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies bamboo cane hanging chairs have a unique and stylish appearance that can add a natural and organic element to any room or outdoor space. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Hammock Swing Sofa Chair",
      price: 166,
      lastPrice : 196,
      image:
      hangingChair4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 32,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies hammock chair is made up of a strong and durable bamboo material that can withstand the elements, making it suitable for outdoor use. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Basket Hanging Chair",
      price: 76,
      lastPrice : 92,
      image:
      hangingChair5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies hanging chair can be a great space-saving solution, particularly in smaller rooms or outdoor areas. The design of the chair allows for a comfortable and relaxing seating experience, with relaxing backrest. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Cane Hanging Swing Chair",
      price: 84,
      lastPrice : 96,
      image:
      hangingChair6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 26,
      featured: "These chairs are a great addition to any home or outdoor space",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Recliner, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies cane hanging chairs offer a range of features that make them a popular choice for those seeking sustainable and stylish furniture. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rectagular Woven Storage Basket",
      price: 11,
      lastPrice : 13,
      image:
      basket1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 46,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan wicker baskets are lightweight and easy to carry, making them perfect for storage and organization in different areas of your home. You can use them to store anything from toys and clothes to books and magazines. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Autmn Oval Wicker Basket",
      price: 10,
      lastPrice : 14,
      image:
      basket2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 56,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan wicker baskets are lightweight and used to store fruits and vegetables, utensils, or dish towels. You can also use them as a decorative piece to hold plants or to store recipe books. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Webbing Wicker Basket",
      price: 9,
      lastPrice : 13,
      image:
      basket3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 52,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " With Ecowoodies rattan wicker baskets, you can organize your home while also doing your part to protect the environment as they are eco-freindly. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Rattan Wicker Baskets",
      price:16 ,
      lastPrice : 22,
      image:
      basket4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 45,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan wicker set of 3 baskets come in different shapes and sizes, making them perfect for a variety of uses. You can use them to store fruits and vegetables, flowers, small articles and even as planters for your indoor plants. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Bowl Bread Woven Fruit Basket",
      price: 9,
      lastPrice : 12,
      image:
      basket5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 45,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " These table top rattan baskets are used to store bread, fruits and vegetables,  papers, books, and office supplies. They can also be used to hold small electronic devices, such as chargers and headphones. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Fruit Bowl Handmade Round Basket",
      price: 10,
      lastPrice : 13,
      image:
      basket6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 45,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies rattan wicker baskets are a sustainable, durable, and versatile storage solution that adds a touch of natural beauty to any room. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Oval Rattan Woven Storage Baskets",
      price: 17,
      lastPrice : 23,
      image:
      basket7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 45,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " This set of 3 rattan wicker baskets come in different shapes and sizes, making them perfect for a variety of uses. You can use them to store fruits and vegetables, flowers, small articles and even as planters for your indoor plants. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Decorative Rattan Basket",
      price: 13,
      lastPrice : 20,
      image:
      basket8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 37,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " These rattan baskets add a natural touch to any decor style while helping to keep your space organized and clutter-free. You can use them to store fruits and vegetables, flowers, small articles and even as planters for your indoor plants. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Storage Rope Basket",
      price: 18,
      lastPrice : 27,
      image:
      basket9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 40,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " These rattan baskets are used to store fruits and vegetables, utensils, or dish towels. You can also use them as a decorative piece to hold plants or to store recipe books. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Storage Wicker Basket",
      price: 14,
      lastPrice : 17,
      image:
      basket10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " These rattan baskets are used to store papers, books, office supplies, fruits and vegetables, utensils, or dish towels. You can also use them as a decorative piece to hold indoor outdoor plants. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rectangular Set of 3 Handwoven Storage Baskets",
      price: 42,
      lastPrice : 57,
      image:
      basket11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " These handwoven baskets are used to store papers, books, office supplies, fruits and vegetables, utensils, or dish towels.They can also be used to hold small electronic devices, such as chargers and headphones. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Rustic Willow Woven Basket ",
      price: 21 ,
      lastPrice : 27,
      image:
      basket12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies willow handwoven baskets are used to store papers, books, office supplies, fruits and vegetables, utensils, or dish towels.They can also be used to hold small electronic devices, such as chargers and headphones. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Wide Wicker Basket ",
      price: 12 ,
      lastPrice : 17,
      image:
      basket13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Ecowoodies rattan wicker baskets are a sustainable, durable, and versatile storage solution that adds a touch of natural beauty to any room. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wall Planter Wicker Rustic Hanging Basket ",
      price: 12 ,
      lastPrice : 17,
      image:
      basket14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 52,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan hanging baskets are commonly used to hold plants and flowers, both indoors and outdoors. They can be hung from a porch or balcony or used to create a vertical garden. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Kitchen Storage Basket With Handle ",
      price: 9 ,
      lastPrice : 13,
      image:
      basket15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 47,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan wicker wall mounted baskets are lightweight and easy to carry, making them perfect for storage and organization in different areas of your home. ",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Large and Medium Willow Flower Baskets ",
      price: 14 ,
      lastPrice : 19,
      image:
      basket16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 47,
      featured: "Rattan wicker baskets are a versatile storage solution that can be used in any room of your home. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Storage, Decorative",
      feature6:"Usage -Home, Garden, Farmhouse, Kitchen, Bathroom, Living Room, Office, Bedroom, Balcony, Terrace, Courtyard, Lounge, Hotel, Restaurant, Resorts ",
      description:
        " Rattan wicker willow baskets are lightweight and can be hung from a porch or balcony or used to create a vertical garden.",
      category: "baskets",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Dolls Pram ",
      price: 42,
      lastPrice : 49,
      image:
      babydollStroller1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 67,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of our little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        " Baby doll strollers provide children with a platform to engage in imaginative play, where they can pretend to be caregivers and learn essential skills such as empathy, communication, and problem-solving.",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Doll Rattan Bed With Wheels ",
      price: 41 ,
      lastPrice : 48,
      image:
      babydollStroller2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 57,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        "Our rattan wicker baby doll strollers are the perfect eco-friendly and sustainable toy for your little ones. Many children develop an emotional attachment to their dolls, and having a stroller to push them around in can provide a sense of comfort and security.",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Unique Wicker Baby Doll Rattan Stroller ",
      price: 47,
      lastPrice : 55,
      image:
      babydollStroller3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 52,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        "Ecowoodies rattan wicker strollers are both sturdy and lightweight, making them easy to handle for kids of all ages. With their charming design and attention to detail, these strollers are sure to delight both children and adults alike. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Eco-freindly Rattan Baby Doll Teddies Stroller",
      price: 39 ,
      lastPrice : 47,
      image:
      babydollStroller4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        "These rattan baby doll prams can be used both indoors and outdoors, making them a great toy for children to take on adventures.",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rattan Baby Doll Stroller",
      price: 42 ,
      lastPrice : 48,
      image:
      babydollStroller5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 46,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        "Ecowoodies rattan strollers encourage children to engage in imaginative play and social interactions with their peers. It can be used in a variety of settings, providing children with endless opportunities for imaginative play and fun. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Dollhouse Wicker Baby Pram",
      price: 38 ,
      lastPrice : 46,
      image:
      babydollStroller6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        "Ecowoodies Baby doll pram can be used as decorations or props at special events and parties, such as baby showers, birthdays, or themed parties. It can be used in a variety of settings, providing children with endless opportunities for imaginative play and fun. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Mianiature Baby Doll Carriage",
      price: 41 ,
      lastPrice : 50,
      image:
      babydollStroller7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured: " Baby doll strollers provide children with an opportunity to engage in imaginative play, develop essential skills, and have fun while caring for their dolls. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll strollers are versatile toys that can be used in a variety of settings, both indoors and outdoors. ",
      description:
        " Baby doll strollers provide children with a platform to engage in imaginative play, these skills include balance, coordination, and strength where they can pretend to be caregivers and learn essential skills such as empathy, communication, and problem-solving. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Mianiature Baby Doll Bed",
      price: 28,
      lastPrice : 37,
      image:
      babydollCrib1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        " The lightweight design of rattan cradles makes them easy to move around, allowing children to play and interact with their dolls in different areas of the home. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Baby Doll Cradle Bed",
      price: 27,
      lastPrice : 36,
      image:
      babydollCrib2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        " The woven design of rattan provides a sturdy and supportive structure for the cradle, ensuring that it can safely hold dolls and stuffed animals. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Rattan Baby Doll Crib",
      price: 28,
      lastPrice : 37,
      image:
      babydollCribA,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        " These rattan cribs can be used as a standalone piece of furniture or incorporated into a child's playroom or bedroom décor. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Dreamy Rattan Handcrafted Baby Doll Cribs",
      price: 33,
      lastPrice : 42,
      image:
      babydollCrib3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        " Ecowoodies Wicker baby doll cradle cribs are made from natural rattan materials, which are lightweight, durable, and environmentally friendly. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Toy Doll Crib",
      price: 29,
      lastPrice : 36,
      image:
      babydollCrib4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        " Rattan baby doll cradle cribs are designed for use as a safe and comfortable sleeping space for dolls, teddies and stuffed animals. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Dreamy Wicker Baby Doll Crib",
      price: 26,
      lastPrice : 34,
      image:
      babydollCrib5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        "These cribs and cradles are carefully woven from sturdy bamboo, ensuring that they can withstand the wear and tear of playtime. Plus, their lightweight design makes them easy to move from room to room. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Baby Doll Rocking Cradle",
      price: 32,
      lastPrice : 39,
      image:
      babydollCrib6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        "Ecowoodies rattan rocking baby doll cradles are made from natural rattan materials, which are lightweight, durable, and environmentally friendly. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Baby Doll Dream Bed",
      price: 30,
      lastPrice : 38,
      image:
      babydollCribB,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: " Its a friendly option for parents and caregivers looking for a stylish and safe sleeping space for their child's dolls and stuffed animals. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"Keeping , carrying and Storing Dolls , Teddies and Soft Toys of your little ones ",
      feature6:"Usage -Baby doll cribs are unique gift or a charming addition to your child's playroom. ",
      description:
        "Dream Beds are hand woven, which provides a sturdy and supportive structure for the bed, ensuring that it can safely hold dolls and stuffed animals. ",
      category: "Babydollstrollers",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Weave Rectangular Large Storage Box",
      price: 56,
      lastPrice : 75,
      image:
      storageBox1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 33,
      featured: " Rattan bamboo storage boxes are a practical, sustainable, and stylish storage option for households of all sizes. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"They are easy to clean and maintain, and their sturdy construction ensures that they will last for many years. ",
      feature6:"Usage -Home, Garden, Kitchen , Bathroom, Hotel, Restaurant ",
      description:
        "The woven design of the rattan and bamboo materials provides a sturdy and supportive structure for the box, ensuring that it can safely hold a variety of household items. ",
      category: "storagebox",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Wicker Storage Box",
      price: 28,
      lastPrice : 30,
      image:
      storageBox2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: " Rattan bamboo storage boxes are a practical, sustainable, and stylish storage option for households of all sizes. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"They are easy to clean and maintain, and their sturdy construction ensures that they will last for many years.",
      feature6:"Usage -Home, Garden, Kitchen , Bathroom, Hotel, Restaurant ",
      description:
        "Rattan bamboo storage boxes often feature a simple yet stylish design, making them a charming addition to any room in the home. ",
      category: "storagebox",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Rattan Wicker Storage Box",
      price: 32,
      lastPrice : 41,
      image:
      storageBox3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: " Rattan bamboo storage boxes are a practical, sustainable, and stylish storage option for households of all sizes. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"They are easy to clean and maintain, and their sturdy construction ensures that they will last for many years.",
      feature6:"Usage -Home, Garden, Kitchen , Bathroom, Hotel, Restaurant ",
      description:
        "The natural materials used in rattan bamboo storage boxes are non-toxic and safe for use in households with children and pets. ",
      category: "storagebox",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Rattan Laundry Box With Lid",
      price: 32,
      lastPrice : 41,
      image:
      storageBox4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: " Rattan bamboo storage boxes are a practical, sustainable, and stylish storage option for households of all sizes. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"They are easy to clean and maintain, and their sturdy construction ensures that they will last for many years.",
      feature6:"Usage -Home, Garden, Kitchen , Bathroom, Hotel, Restaurant ",
      description:
        " These are versatile and functional, allowing you to store a wide range of items in a stylish and organized manner. ",
      category: "storagebox",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Large Medium Bamboo Storage Chest",
      price: 94,
      lastPrice : 120,
      image:
      storageBox5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: " Rattan bamboo storage boxes are a practical, sustainable, and stylish storage option for households of all sizes. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Wicker, Cane",
      feature4:"100% Recycle",
      feature5:"They are easy to clean and maintain, and their sturdy construction ensures that they will last for many years.",
      feature6:"Usage -Home, Garden, Kitchen , Bathroom, Hotel, Restaurant ",
      description:
        "These handmade rattan bamboo storage boxes come in a variety of sizes and shapes, making it easy to find a box that fits your specific storage needs. ",
      category: "storagebox",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Bamboo Amplifier and Mobile Holder",
      price: 7,
      lastPrice : 8,
      image:
      bambooAmplifier1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 58,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Bamboo Amplifiers are lightweight and portable, making them easy to carry around and use wherever you go. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Mobile Phone Holder Cum Sound Amplifier",
      price: 6,
      lastPrice : 7,
      image:
      bambooAmplifier2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Our bamboo amplifiers are designed to deliver crisp and clear sound, amplifying the natural tones of your music without compromising on quality. The unique properties of bamboo as a natural material make for a warm and authentic sound that is unmatched by synthetic materials. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "2 in 1 Bamboo Amplifier Speaker or Phone Holder",
      price: 5,
      lastPrice : 6,
      image:
      bambooAmplifier3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Our bamboo amplifiers Bamboo are compact, portable, and easy to use, making them a great addition to any music setup. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Amplifier and Pen Stand",
      price: 7,
      lastPrice : 8,
      image:
      bambooAmplifier4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Bamboo amplifiers are designed to enhance the natural tones of music, producing a crisp and clear sound. Bamboo is also lightweight and portable, making bamboo amplifiers easy to carry around. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Amplifier Mobile Stand ",
      price: 6,
      lastPrice : 7,
      image:
      bambooAmplifier5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Bamboo is a strong and durable material that can withstand wear and tear, ensuring that your bamboo amplifier will last for years. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Designer Bamboo Amplifier Speaker",
      price: 6,
      lastPrice : 7,
      image:
      bambooAmplifier6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 44,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Bamboo amplifiers are used to amplify the sound of music players like smartphones, laptops, and tablets. They are designed to deliver a clear and natural sound that enhances the music listening experience. ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Portable Bamboo Amplifier Speaker",
      price: 6,
      lastPrice : 7,
      image:
      bambooAmplifier7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        " Bamboo amplifiers are designed to enhance the natural tones of music, producing a crisp and clear sound. Bamboo is also lightweight and portable, making bamboo amplifiers easy to carry around. ",
      category: "Bambooamplifier",
      stock: 4,
    },  
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboobeat Sound Amplifier",
      price: 7,
      lastPrice : 8,
      image:
      bambooAmplifier9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 46,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        "Ecowoodies handmade bamboo products ensure that you are getting a quality product that is designed to last. One of the benefits of bamboo as a material is that it is known for producing warm and authentic sound.  ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Sound-Amplifier",
      price: 6,
      lastPrice : 7,
      image:
      bambooAmplifier10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 36,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Bamboo Amplifiers offer several benefits to music lovers who are looking for an eco-friendly, durable, and cost-effective way to amplify their music. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office ",
      description:
        "Bamboo amplifier is an eco-friendly choice for music lovers. One of the benefits of bamboo as a material is that it is known for producing warm and authentic sound.  ",
      category: "Bambooamplifier",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Ergonomic Bamboo Cane Sofa Set",
      price: 230,
      lastPrice : 280,
      image:
      sofaSet1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 46,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan , Cane",
      feature4:"100% Recycle",
      feature5:"Crafted from eco-friendly and fully recyclable materials, our sofa set is the perfect choice for those seeking sustainable and stylish furniture for their home.",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        "Ecowoodies sofa sets are versatile and can be used both indoors and outdoors, depending on your preference. They can be used in the living room, sunroom, or on the patio.  ",
      category: "Sofaset",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Stylish Sofa Couch",
      price: 64,
      lastPrice : 72,
      image:
      sofaSet2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 56,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan , Cane",
      feature4:"100% Recycle",
      feature5:"Crafted from eco-friendly and fully recyclable materials, our sofa set is the perfect choice for those seeking sustainable and stylish furniture for their home.",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        "Our Sofa Couch can be used both indoors and outdoors, depending on your preference. Despite its strength, it is lightweight, making it easy to move the sofa from one location to another.It can be used in the living room, sunroom, or on the patio.  ",
      category: "Sofaset",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Willow Wicker Beige Sofa Set",
      price: 190,
      lastPrice : 240,
      image:
      sofaSet3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan , Cane",
      feature4:"100% Recycle",
      feature5:"Crafted from eco-friendly and fully recyclable materials, our sofa set is the perfect choice for those seeking sustainable and stylish furniture for their home.",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        " The natural textures and warm tones of the bamboo and rattan cane give the sofa set a unique and organic aesthetic, adding a touch of natural elegance to any space. They can be used in the living room, sunroom, or on the patio.  ",
      category: "Sofaset",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rattan Patio Red Sofa Set ",
      price: 210,
      lastPrice : 250,
      image:
      sofaSet4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan , Cane",
      feature4:"100% Recycle",
      feature5:"Crafted from eco-friendly and fully recyclable materials, our sofa set is the perfect choice for those seeking sustainable and stylish furniture for their home.",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        " The natural textures and warm tones of the bamboo and rattan cane give the sofa set a unique and organic aesthetic, adding a touch of natural elegance to any space. They can be used in the living room, sunroom, or on the patio.  ",
      category: "Sofaset",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bar Harbor Wicker Bamboo Sofa Chairs Set ",
      price: 110,
      lastPrice : 150,
      image:
      sofaSet5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: " Clean with a duster or a damp cloth when dirty. ",
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan , Cane",
      feature4:"100% Recycle",
      feature5:"Crafted from eco-friendly and fully recyclable materials, our sofa set is the perfect choice for those seeking sustainable and stylish furniture for their home.",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        "Ecowoodies Rattan Bamboo Sofa Chairs are 100 % Eco-freindly . The durability, lightweight nature, and unique aesthetic appeal make them a versatile choice for any living space. ",
      category: "Sofaset",
      stock: 4,
    }, 
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Bistro Round Dining Table Chair Set ",
      price: 190,
      lastPrice : 240,
      image:
      bambooDiningset1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, lounge ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Four Bamboo Chairs with round dining table sets have a unique and natural look that adds warmth and texture to any dining room, balcony , garden. They come in a variety of styles and finishes, from sleek and modern to rustic and traditional. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Handcrafted 4 Seater Dining Table Set ",
      price: 240,
      lastPrice : 270,
      image:
      bambooDiningset2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies Bamboo dining table sets are sturdy and can withstand regular use, making them a long-lasting investment. They come in a variety of styles and finishes, from sleek and modern to rustic and traditional. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Darkbrown Handmade Bamboo 8 Seater Dining Set ",
      price: 310,
      lastPrice : 350,
      image:
      bambooDiningset3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 34,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies use traditional techniques to create unique and stylish designs that reflect the rich culture and heritage of their respective regions. Each piece is handcrafted with attention to detail, ensuring that every Bamboo Dining Set is truly one-of-a-kind.",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj231",
      name: "Vintage Handcrafted Round Dining Table Chair Set ",
      price: 200,
      lastPrice : 320,
      image:
      bambooDiningSet16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Office, Terrace, Farmhouse, Resorts, Living Room, Bar, Pubs  ",
      description:
        "Four Bamboo Chairs with round  dining table sets have a unique and natural look that adds warmth and texture to any dining room, balcony , garden. They come in a variety of styles and finishes, from sleek and modern to rustic and traditional. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Grove 8 Seater Dining Set ",
      price: 320,
      lastPrice : 360,
      image:
      bambooDiningset4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies Bamboo Dining Set is made from natural and high-quality bamboo, which is known for its strength, durability, and aesthetic appeal. It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Coastal 4 Seater Bamboo Dining Set  ",
      price: 240,
      lastPrice : 290,
      image:
      bambooDiningset5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies use traditional techniques to create unique and stylish designs that reflect the rich culture and heritage of their respective regions. Our dining sets are made from natural and high-quality bamboo, which is known for its strength, durability, and aesthetic appeal.  ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo 4 Seater Royal Round Dining Set  ",
      price: 320,
      lastPrice : 380,
      image:
      bambooDiningset6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies use traditional techniques to create eco-friendly ,  unique and stylish designs that reflect the rich culture and heritage of their respective regions. Our dining sets are made from natural and high-quality bamboo, which is known for its strength, durability, and aesthetic appeal.  ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bistro Cane 4 Seater Round Dining Set  ",
      price: 260,
      lastPrice : 290,
      image:
      bambooDiningset7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 33,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies Dining Sets come in a variety of styles and finishes, from sleek and modern to rustic and traditional. Our dining sets are made from natural and high-quality cane, which is known for its strength, durability, and aesthetic appeal.  ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Beige Bamboo Stylish 4 Seater Dining Set  ",
      price: 260,
      lastPrice : 300,
      image:
      bambooDiningset8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "Ecowoodies exquisite and eco-friendly Bamboo Dining Sets come in a variety of styles and finishes, from sleek and modern to rustic and traditional. Our dining sets are made from natural and high-quality bamboo, which is known for its strength, durability, and aesthetic appeal.  ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Modern Bamboo 2 Seater Round Dining Set  ",
      price: 210,
      lastPrice : 260,
      image:
      bambooDiningset9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        "This natural bamboo dining set is stylish and eco-freindly and also come in a variety of styles and finishes, from sleek and modern to rustic and traditional.  Each piece is handcrafted with attention to detail, ensuring that every Bamboo Dining Set is truly one-of-a-kind. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Modern  4 Seater Round Cane Dining Set  ",
      price: 270,
      lastPrice : 300,
      image:
      bambooDiningset10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. Our exquisite and eco-friendly Bamboo Dining Sets come in a variety of styles and finishes, from sleek and modern to rustic and traditional. ",
      category: "Bamboodiningsets",
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Heritage Round Wooden Dining Table With 4 Chairs ",
      price: 280,
      lastPrice : 330,
      image:
      bambooDiningset11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Wood",
      feature4:"100% Recycle",
      feature5:" A Canae Bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " These handmade unique and stylish designs reflect the rich culture and heritage of their respective regions. It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. ",
      category: "Bamboodiningsets",
      stock: 4,
     },
     {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handcrafted Cane Round Dining Table With 4 Chairs ",
      price: 260,
      lastPrice : 300,
      image:
      bambooDiningset12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:" A Cane dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " Ecowoodies use traditional techniques to create eco-friendly ,  unique and stylish designs that reflect the rich culture and heritage of their respective regions. It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. ",
      category: "Bamboodiningsets",
      stock: 4,
     },
     {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Bliss Iron 4 Seater Dining Set ",
      price: 280,
      lastPrice : 330,
      image:
      bambooDiningset13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Iron",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " Bamboo and iron crafted to make stylish designs that reflect the rich culture and heritage of their respective regions. It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. ",
      category: "Bamboodiningsets",
      stock: 4,
     },
     {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Aesthetics 4 Seater Dining Set ",
      price: 270,
      lastPrice : 310,
      image:
      bambooDiningset14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 44,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " Ecowoodies bamboo dining set evokes a sense of warmth and earthiness, which could be a great fit for a dining room with a farmhouse or cottage vibe. It's designed to last for years and can withstand everyday wear and tear, making it an ideal addition to any dining room. ",
      category: "Bamboodiningsets",
      stock: 4,
     },
     {
      id: "recoW8ecgjtKx2Sj2",
      name: " Vintage Outdoor Round  4 Seater Bamboo Dining Set ",
      price: 250,
      lastPrice : 300,
      image:
      bambooDiningset15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 40,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Iron ",
      feature4:"100% Recycle",
      feature5:" A bamboo dining table set is an excellent investment for those looking for a sustainable and stylish addition to their dining room, dining area. ",
      feature6:"Usage -Home, Garden, Hotel, Restaurant, Resorts, Dining Area ",
      description:
        " Experience the beauty of natural and sustainable materials with our handcrafted Bamboo 4 Seater dining Set, made by skilled artisans from the North East States of India. This elegant and durable furniture set is the perfect addition to your living room, adding both comfort and style to your home. Our furniture manufacturing company takes pride in our commitment to sustainability and traditional craftsmanship. Each piece in this sofa set is handmade with meticulous attention to detail, ensuring that every sofa is unique and of the highest quality. The bamboo used in this sofa set is a sustainable and eco-friendly material that's also known for its strength and durability. With proper care, your bamboo sofa set can last for years, making it a practical and cost-effective choice for your home. Not only is this sofa set beautiful and functional, but it also adds a touch of natural beauty to your living space. The natural grain and texture of the bamboo gives this set a unique character that sets it apart from other furniture options. Order yours today and experience the natural beauty and durability of bamboo. ",
      category: "Bamboodiningsets",
      stock: 4,
     },
     {
      id: "recoM2MyHJGHLVi5l",
      name: "Newborn Baby Bambino Bench",
      price: 17,
      lastPrice :25,
      image:
      newborn1,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 52,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our Handmade bamboo rattan newborn photography props are extremely durable, yet lightweight. This makes them easy to transport, set up, and store, while also ensuring that they can withstand the wear and tear of regular use.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Newborn Bamboo Dreams Cradle ",
      price: 28,
      lastPrice :39,
      image:
      newborn2,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 55,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        " These handmade bamboo newborn photography props are designed to be safe and comfortable for newborns. The materials are soft and gentle, and the props are designed to provide a secure and stable environment for newborns to rest in during photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Soft Heart Shape Newborn Photography Prop",
      price: 27,
      lastPrice :36,
      image:
      newborn3,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 55,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane,  Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Basket for Photography Shoot",
      description:
        "This soft heart shape crib has a natural beauty and warmth that creates a soothing and calming environment for babies. Its aesthetic appeal, creating a unique and organic look.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Wicker Newborn Photography Shoot Basket",
      price: 29,
      lastPrice :38,
      image:
      newborn4,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 52,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Handmade rattan newborn photography prop is unique, with its own character and style.Their natural beauty, safety features, and versatility, rattan bamboo baby beds are the perfect choice for parents who want to create a warm and welcoming environment for their little ones.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Willow Rattan Newborn Photography Prop Crib",
      price: 26,
      lastPrice :32,
      image:
      newborn5,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 63,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan , Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish ",
      description:
        " These Ecowoodies cribs are designed to be safe and comfortable for babies. The smooth and rounded edges of the bamboo provide a soft and gentle surface for babies to rest on, while the sturdy construction ensures that the crib or cradle is stable and secure.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Vintage Soft Newborn Baby Bassinet Crib",
      price: 26,
      lastPrice :35,
      image:
      newborn6,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 61,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"",
      description:
        "These cribs are designed to be safe and comfortable for babies. The smooth and rounded edges of the bamboo provide a soft and gentle surface for babies to rest on, while the sturdy construction ensures that the crib or cradle is stable and secure.Ecowoodies rattan cane  cribs are eco-freindly and  their unique beauty, durability, and versatility, these props are the perfect addition to any newborn photo shoot.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Woven Rattan Newborn Photography Props",
      price: 27,
      lastPrice :33,
      image:
      newborn7,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Rattan  ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Crib for Photography",
      description:
        "Our rattan cane cribs are eco-freindly and  their unique beauty, durability, and versatility, these props are the perfect addition to any newborn photo shoot.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Bamboo Bliss Newborn Baby Cradle",
      price: 29,
      lastPrice :39,
      image:
      newborn8,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 59,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Bamboo cradles come in a range of styles and designs, from traditional cradles to modern cribs. This versatility makes it easy to find a bamboo baby bed that fits your style and needs.Ecowoodies cradles are designed to be safe and comfortable for babies. The smooth and rounded edges of the bamboo provide a soft and gentle surface for babies to rest on, while the sturdy construction ensures that the crib or cradle is stable and secure.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Wooden Newborn Photography Prop",
      price: 26,
      lastPrice :38,
      image:
      newborn9,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Box for Photography Shoot",
      description:
        "This stylish wooden box is perfect for creating a warm and welcoming atmosphere for newborn photo shoots.The materials are soft and gentle, and the props are designed to provide a secure and stable environment for newborns to rest in during photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rustic Bamboo Newborn Baby Cradle",
      price: 31,
      lastPrice :40,
      image:
      newborn10,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 64,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Newborn Baby Cradle for Photography",
      description:
        "Bamboo cradles come in a range of styles and designs, from traditional cradles to modern cribs. This versatility makes it easy to find a bamboo baby bed that fits your style and needs.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: " Newborn Photography Prop Golden Bamboo Crib",
      price: 29,
      lastPrice :37,
      image:
      newborn11,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Newborn Baby Bamboo Basket Crib for Photography",
      description:
        "Ecowoodies handmade newborn photography props cribs offer a natural and eco-friendly option for capturing those special early moments with your newborn.This stylish bamboo crib is perfect for creating a warm and welcoming atmosphere for newborn photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Newborn Baby Bamboo Cradle",
      price: 30,
      lastPrice :40,
      image:
      newborn12,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 64,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our handmade bamboo rattan newborn photography prop is unique, with its own character and style. The handcrafted nature of these props adds to their aesthetic appeal, creating a one-of-a-kind backdrop for your newborn photography.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Dark Brown Newborn Baby Bamboo Cradle ",
      price: 33,
      lastPrice :40,
      image:
      newborn13,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 66,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern Stylish",
      description:
        "Our Bamboo cradles come in a range of styles and designs, from traditional cradles to modern cribs. This versatility makes it easy to find a bamboo baby bed that fits your style and needs.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Newborn Baby Photography Prop Rustic Basket",
      price: 20,
      lastPrice :28,
      image:
      newborn14,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 56,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Basket for Photography",
      description:
        "Ecowoodies handmade newborn photography props baskets offer a natural and eco-friendly option for capturing those special early moments with your newborn.This stylish bamboo crib is perfect for creating a warm and welcoming atmosphere for newborn photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Oval Basket Newborn Photography Prop ",
      price: 20,
      lastPrice :27,
      image:
      newborn15,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 51,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan , Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Oval Basket For Newborn Photography",
      description:
        "Ecowoodies handmade newborn photography props baskets offer a natural and eco-friendly option for capturing those special early moments with your newborn.This stylish bamboo crib is perfect for creating a warm and welcoming atmosphere for newborn photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Wicker Bucket Bassinet Newborn Photography Shoot",
      price: 22,
      lastPrice :31,
      image:
      newborn16,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 51,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Bucket for Photography Shoot",
      description:
        "Ecowoodies handmade newborn photography props bucket baskets offer a natural and eco-friendly option for capturing those special early moments with your newborn.This stylish bamboo crib is perfect for creating a warm and welcoming atmosphere for newborn photo shoots.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Newborn Photography Prop Chair",
      price: 27,
      lastPrice :39,
      image:
      newborn17,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 51,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Soft Baby Chair for Photography",
      description:
        "Introducing our Sarkanda Baby Chair, handcrafted with love by local artisans of North India. Made from sustainable sarkanda materials, this chair is the perfect addition to your baby's nursery or any other cozy corner of your home. Our furniture manufacturing company takes pride in our commitment to sustainability and traditional craftsmanship. Each Sarkanda Baby Chair is handmade by local artisans, ensuring that every piece is unique and of the highest quality. Designed with both comfort and safety in mind, this chair provides optimal support for your baby while still being stylish and functional. Its natural materials and simple design complement any nursery decor, adding a touch of natural beauty to your home. Sarkanda is a durable and eco-friendly material that has been used for centuries to make furniture and other household items. By using sustainable materials, we're able to create beautiful, functional furniture without harming the environment. Invest in sustainable and stylish furniture with our Sarkanda Baby Chair. Order yours today and give your baby a safe and comfortable place to rest and play.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rattan Ped Bed",
      price: 27,
      lastPrice :36,
      image:
      petBed1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 29,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "Our natural rattan pet beds offer a stylish and eco-friendly option for pet owners who care about sustainability and quality. With their natural beauty, durability, and comfort, these pet beds provide a cozy and inviting place for your furry friends to rest and relax.",
      category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Handwoven Pet Chair",
      price: 25,
      lastPrice :35,
      image:
      petBed2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 37,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "This Pet bed chair is designed to provide a soft and comfortable place for your pet to rest, which is important for their overall health and well-being.The natural variations and textures of the rattan give these pet beds chairs a unique and organic appearance, adding to the overall aesthetic appeal of any room.",
      category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Rattan Rectangle Pet Bed",
      price: 28,
      lastPrice :37,
      image:
      petBed3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 27,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "This rectangular Pet bed can help alleviate joint pain, reduce pressure points, and provide a cozy place for your pet to relax which is important for their overall health and well-being.The natural variations and textures of the rattan give these pet beds  a unique and organic appearance, adding to the overall aesthetic appeal of any room.",
      category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Cane Rounding Edges Pet Bed",
      price: 28,
      lastPrice :37,
      image:
      petBed4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 32,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Ecowoodies round pet beds are a versatile and essential piece of pet furniture that can provide comfort, hygiene, safety, training, and style for your furry friend. By choosing a high-quality and eco-friendly pet bed, you can provide your pet with the best possible sleeping experience while also supporting sustainable and ethical manufacturing practices. "
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Rest Pet Bed",
      price: 27,
      lastPrice :38,
      image:
      petBed5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 34,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Ecowoodies round shaped pet bed provides a contained space for your pet to sleep and rest, reducing the spread of hair, dander, and dirt around your home. "
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Cane Comfort Pet Bed Cot ",
      price: 28,
      lastPrice :37,
      image:
      petBed6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Each of our bamboo cane pet bed is handmade by local artisans using traditional techniques. This adds to the unique character and beauty of each pet bed, making them a one-of-a-kind addition to your home."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Cane Wicket Pet Pavilion Bed",
      price: 28,
      lastPrice :37,
      image:
      petBed7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Our bamboo cane pet beds are handmade  and their natural beauty, durability, and comfort, these pet beds provide a cozy and inviting place for your furry friends to rest and relax."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Brown Rectangular Sleeping Pet Bed",
      price: 26,
      lastPrice :36,
      image:
      petBed8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 34,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan,  Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Our rattan cane pet beds are handmade, versatile and essential piece of pet furniture that can provide comfort, hygiene, safety, training, and style for your furry friend, these pet beds provide a cozy and inviting place for your furry friends to rest and relax."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Bamboo Babino Pet Bed",
      price: 25,
      lastPrice :34,
      image:
      petBed9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 33,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bammboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Our bamboo cane rattan pet beds offer a stylish and eco-friendly option for pet owners who care about sustainability and quality. With their natural beauty, durability, and comfort, these pet beds provide a cozy and inviting place for your furry friends to rest and relax."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Stylish Rattan Pet Bed",
      price: 26,
      lastPrice :34,
      image:
      petBed10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 23,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " The primary function of our pet bed is to provide a soft and comfortable surface for your pet to rest and sleep on. High-quality pet beds are designed to support your pet's body and alleviate pressure points, ensuring that they get a good night's sleep."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Chair Pet Bed",
      price: 28,
      lastPrice :37,
      image:
      petBed11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 23,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " The function of this unique design of pet bed chair is to provide a soft and comfortable surface for your pet to rest and sleep on. High-quality pet beds are designed to support your pet's body and alleviate pressure points, ensuring that they get a good night's sleep."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },  
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Oval Shaped Indoor Rattan Pet Bed",
      price: 26,
      lastPrice :34,
      image:
      petBed12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 23,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " Ecowoodies pet beds are built to last, ensuring that they provide a long-term solution for your pet's sleeping needs, these are a versatile and essential piece of pet furniture that can provide comfort, hygiene, safety, training, and style for your furry friend. By choosing a high-quality and eco-friendly pet bed, you can provide your pet with the best possible sleeping experience "
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Portable Pet Bed",
      price: 28,
      lastPrice :40,
      image:
      petBed13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 28,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        " This natural variations and textures of the  rattan give these pet beds a unique and organic appearance, adding to the overall aesthetic appeal of any room."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Cave Pet Bed",
      price: 28,
      lastPrice :40,
      image:
      petBed14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 32,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  The canopy design of this pet bed provides your pet with a cozy and enclosed space to rest and sleep in. The rattan material also offers a soft and comfortable surface for your pet to lay on."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Luxury Soft Rattan Travell Pet Bed",
      price: 29,
      lastPrice :40,
      image:
      petBed15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 32,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  This type of pet bed can be used indoors or outdoors, making it a versatile choice for pet owners who want a bed that can be moved around as needed."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Non-Slip Pet Bed",
      price: 29,
      lastPrice :40,
      image:
      petBed16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  The canopy design of this pet bed provides your pet with a sense of privacy and security, which can be particularly important for anxious or nervous pets."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rattan Canopy Pet Bed",
      price: 28,
      lastPrice :40,
      image:
      petBed17,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  The design of the rattan canopy pet bed can help keep your pet's sleeping area clean and free of dirt and debris. This can reduce the spread of allergens and keep your home hygienic."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Outdoor Rest Pet Bed",
      price: 30	,
      lastPrice :42,
      image:
      petBed18,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  The rattan canopy design adds a touch of elegance and sophistication to any room. It is a stylish and modern choice that can complement a range of interior design styles."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Pet Bed Lounge",
      price: 27	,
      lastPrice :39,
      image:
      petBed19,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  The canopy design of this pet bed provides your pet with a sense of privacy and security, which can be particularly important for anxious or nervous pets."
    ,category: "PetBeds",
      amount: 1,
      stock: 4,
    },    
    {
      id: "recd1jIVIEChmiwhe",
      name: "Rattan Recline Sun Lounger",
      price: 42,
      lastPrice :55,
      image: sunLounger1,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 39,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan , Sarkanda",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        "Ecowoodies rattan sun lounger sunbeds are also incredibly stylish. With their natural materials, woven design, and excellent ventilation, these sunbeds and sun loungers provide a comfortable and eco-friendly way to enjoy the outdoors. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Rattan Woven Recline Sunbed",
      price: 44,
      lastPrice :58,
      image: sunLounger2,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan , Sarkanda",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        "These beige color natural rattan sun lounger sunbeds are also incredibly stylish. With their natural materials, woven design, and excellent ventilation, these sunbeds and sun loungers provide a comfortable and eco-friendly way to enjoy the outdoors. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "EcoLounger Bamboo Sun Lounger Chairs",
      price: 90,
      lastPrice :120,
      image: sunLounger3,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 49,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo" ,
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " Whether you're lounging by the pool or soaking up the sun in your backyard, bamboo  sunbeds and sun loungers are the perfect choice for stylish and sustainable outdoor relaxation.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Rattan Breeze Sunbath Reclining Chair",
      price: 45,
      lastPrice :58,
      image: sunLounger4,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These Sunbath Chairs are lightweight, adjustable and easy to move, making them a great option for camping or beach trips. Whether you're lounging by the pool or soaking up the sun in your backyard, cane rattan sunbath chairs and sun loungers are the perfect choice for stylish and sustainable outdoor relaxation.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Bamboo Bliss Bambino Sun Lounger",
      price: 46,
      lastPrice :60,
      image: sunLounger5,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 42,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These sunbath chairs and sun loungers provide a comfortable and eco-friendly way to enjoy the outdoors or beach trips. Whether you're lounging by the pool or soaking up the sun in your backyard, bamboo sunbath chairs and sun loungers are the perfect choice for stylish and sustainable outdoor relaxation.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Outdoor Sunbath Rattan Chair",
      price: 38,
      lastPrice :52,
      image: sunLounger6,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " Our rattan and bamboo lounge chairs can be used both indoors and outdoors. They are lightweight and easy to move, which makes them ideal for use on patios, decks, or in the garden. Whether you're lounging by the pool or soaking up the sun in your backyard, cane rattan sunbath chairs and sun loungers are the perfect choice for stylish and sustainable outdoor relaxation.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Handcrafted Rattan Sunbed",
      price: 39,
      lastPrice :55,
      image: sunLounger7,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 42,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan" ,
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These Multicolor rattan  sunbed and sun loungers are a stylish and sustainable alternative to traditional outdoor furniture.They are sturdy and durable, withstanding wear and tear over time. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Outdoor Sunbath Bamboo Beach Lounger",
      price: 48,
      lastPrice :60,
      image: sunLounger8,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo" ,
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        "These chairs typically have a longer, reclined design and are great for sunbathing. They can often be adjusted to different positions for optimal comfort.They are sturdy and durable, withstanding wear and tear over time. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Outdoor Sunbed Bamboo Beach Lounger",
      price: 98,
      lastPrice :125,
      image: sunLounger9,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 42,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo" ,
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These sunbeds and sun loungers provide a comfortable and eco-friendly way to enjoy the outdoors.They are sturdy and durable, withstanding wear and tear over time. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Cane Coast Sunbed",
      price: 34,
      lastPrice :49,
      image: sunLounger10,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 51,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane" ,
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These rattan and bamboo lounge chairs can also be used in bedrooms as reading chairs or as a stylish alternative to traditional seating. Adjustable backrests provide customizable comfort for all users. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Handmade Rattan Rustic Lounger",
      price: 42,
      lastPrice :55,
      image: sunLounger11,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 56,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These are lightweight and easy to move around, making them perfect for outdoor use. Adjustable backrests provide customizable comfort for all users. ",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Set of 2 Handcrafted Outdoor Relaxation Loungers",
      price: 89,
      lastPrice :108,
      image: sunLounger12,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 46,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        "This set of  rattan and bamboo lounge chairs can add a touch of natural style to your living room, garden or pool . They can be paired with cushions or throws to create a cozy and inviting space.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Vintage Rattan Cane Sunlounger",
      price: 40,
      lastPrice :48,
      image: sunLounger13,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 56,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " Its a lightweight lounge chair  gives   stylish and natural look to outdoor spaces and its adjustable backrests provide customizable comfort for all users.These can be used in both residential and commercial settings, such as resorts and hotels.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Bamboo Eco-freindly Sunbed",
      price: 49,
      lastPrice :58,
      image: sunLounger14,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 59,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " Whether you're lounging by the pool, soaking up the sun on the beach, or simply enjoying a lazy afternoon in your backyard, our bamboo cane rattan sun loungers/sunbeds are the perfect addition to your outdoor living space.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Canevibe  Lounger Chair",
      price: 44,
      lastPrice :52,
      image: sunLounger15,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 49,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " This is designed to provide maximum comfort and relaxation. The sturdy bamboo and rattan construction ensures long-lasting durability and resistance to the elements, while the lightweight design makes them easy to move and store.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Canevibe Rustic Lounge Chair",
      price: 45,
      lastPrice :52,
      image: sunLounger16,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 49,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These rattan and bamboo lounge chairs can be used both indoors and outdoors. They are lightweight and easy to move, which makes them ideal for use on patios, decks, or in the garden. The sturdy bamboo and rattan construction ensures long-lasting durability and resistance to the elements, while the lightweight design makes them easy to move and store.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Handmade Outdoor Reclining Lounge Chair ",
      price: 43,
      lastPrice :52,
      image: sunLounger17,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 29 ,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " These rattan and bamboo have a natural, organic look that is both timeless and stylish. They can add a touch of warmth and texture to any room or outdoor space.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: " Handcrafted Outdoor Reclining Sunlounger ",
      price: 48,
      lastPrice :60,
      image: sunLounger18,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      reviews: 38 ,
      featured:false,
      feature1:"100% Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Traditional",
      description:
        " Our bamboo and rattan lounge chairs are fully recyclable and biodegradable, making them an environmentally responsible choice for your outdoor furnitur Bamboo sunbed and sun loungers are a stylish and sustainable alternative to traditional outdoor furniture.They are sturdy and durable, withstanding wear and tear over time.Their sturdy bamboo and cane construction ensures long-lasting durability and resistance to the elements, while the lightweight design makes them easy to move and store.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Baby Blue Chair",
      price: 28,
      lastPrice :37,
      image:
      kidChair1,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 59,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        " If you are looking for a comfortable and stylish seating option for your child's bedroom or playroom, bamboo rattan kids' chairs and baby chairs are the perfect choice for parents who value sustainability and style. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Wicker Children's Chair",
      price: 26,
      lastPrice :37,
      image:
      kidChair2,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 52,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Kidchairs",
      description:
        " This Rattan kids' chair and baby chair is a sustainable and stylish alternative to traditional children's furniture. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Sustainable Bamboo Rattan Armchair",
      price: 28,
      lastPrice :40,
      image:
      kidChair3,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        " Ecowoodies bamboo rattan  kids' armchairs are perfect for use in playrooms, bedrooms, or as a part of outdoor furniture sets.",
      category: "Chairs2",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Natural Rattan Nesting Black Baby Chair",
      price: 29,
      lastPrice :40,
      image:
      kidChair4,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 47,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        " This rattan chair  provides a comfortable and supportive seating option for babies, helping to improve posture and prevent discomfort.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Natural Rattan Beige Toddler Chair",
      price: 27,
      lastPrice :36,
      image:
      kidChair5,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "These rattan chairs  are lightweight and easy to move, making them a great option for parents who want to create a flexible seating arrangement for their children.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Wicker Handmade Set Of 2 Toddler Chairs",
      price: 55,
      lastPrice :74,
      image:
      kidChair6,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 35,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "The woven design of these rattan bamboo materials provides excellent ventilation, keeping children cool and comfortable.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Eco-Safari Baby Throne Kids Chair",
      price: 26,
      lastPrice :35,
      image:
      kidChair7,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This bamboo rattan baby chairs are a practical, sustainable, and stylish option for parents who want to provide their children with a comfortable and eco-friendly seating option. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Organic Oasis Rattan Wicker Chair",
      price: 28,
      lastPrice :38,
      image:
      kidChair8,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Our Bamboo Rattan  kids' armchairs are perfect for use in playrooms, bedrooms, or as a part of outdoor furniture sets. ",
      category: "Chairs2",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Nesting Blue Baby Chair",
      price: 28,
      lastPrice :38,
      image:
      kidChair9,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 37,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This rattan chair  provides a comfortable and supportive seating option for babies, helping to improve posture and prevent discomfort. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Set Of 2 Toddlers Rattan Garden Chairs",
      price: 55,
      lastPrice :75,
      image:
      kidChair10,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 44,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Our Bamboo Rattan  Toddler' chairs are a sustainable and stylish alternative to traditional children's furniture.They are easy to maintain and clean, ensuring that they will last for many years. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Rattan Kids Table Chairs Set",
      price: 102,
      lastPrice :135,
      image:
      kidChair11,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Our Bamboo Rattan kids table and chairs set are  practical, sustainable, and stylish option for parents who want to provide their children with a comfortable and eco-friendly seating option. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Wicker Wonder Kids Chair",
      price: 26,
      lastPrice :35,
      image:
      kidChair12,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 62,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "If you're looking for a comfortable and stylish seating option for your child's bedroom or playroom, bamboo rattan kids' chairs and baby chairs are the perfect choice for parents who value sustainability and style. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Vintage Cane Toddler Chair",
      price: 30,
      lastPrice :39,
      image:
      kidChair13,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 62,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "These cane chairs are lightweight and easy to move, making them a great option for parents who want to create a flexible seating arrangement for their children. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Outdoor Kids Garden Desk Chair",
      price: 29,
      lastPrice :38,
      image:
      kidChair14,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 60,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This rattan kids garden chair is made from natural materials, woven design, and excellent ventilation, these chairs provide a comfortable and eco-friendly way for children to relax, play, and learn.  ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Minimalist Kids Rattan Furntire",
      price: 78,
      lastPrice :100,
      image:
      kidChair15,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 60,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "The natural and elegant design of the chairs adds a touch of style to your child's space.This handmade kids table chairs  set is made from natural rattan , handwoven design, and excellent ventilation, these chairs provide a comfortable and eco-friendly way for children to relax, play, and learn.  ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Stackable Wicker Kids Chair",
      price: 26	,
      lastPrice :35,
      image:
      kidChair16,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 72,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Rattan Cane adds an element of flexibility to the furniture, making it comfortable for your child to sit and play.The natural and elegant design of the chairs adds a touch of style to your child's space.  ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Bamboo Rattan Kids Chair and Table set",
      price: 78	,
      lastPrice :95,
      image:
      kidChair17,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 64,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Our kids chair set is designed with your child's safety and comfort in mind. The natural materials used in our chairs make them durable and sturdy, yet lightweight and easy to move around.  ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Scandinavian-style Kids Cane Chair",
      price: 32	,
      lastPrice :40,
      image:
      kidChair18,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 64,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Rattan Cane is a flexible and sturdy material that adds an element of comfort to the chairs. Our kids chair is designed with your child's safety and comfort in mind.  ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Tiny Heaven Rattan Kids Chair",
      price: 30	,
      lastPrice :40,
      image:
      kidChair19,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 70,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This chair is not only stylish but also very durable, making it ideal for your child's everyday use. It is  lightweight and easy to move, allowing your child to move it around and play comfortably. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Bamboo Rattan Buddies Chair Table Set",
      price: 80	,
      lastPrice :100,
      image:
      kidChair20,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 80,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "The round rattan table come with comfortable chairs to ensure your little ones can sit and play for hours on end. The accompanying table is perfect for snacks, drawing, or reading.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Miniature Majesty Toddlers Camping Rattan Chair",
      price: 28	,
      lastPrice :37,
      image:
      kidChair21,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "Bamboo Rattan kids armchair is the perfect combination of comfort, style, and eco-friendliness. Handmade by skilled artisans from Assam, Tripura, and Nagaland, this armchair is designed with your child's safety and comfort in mind. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rattan Junior Gaming Chair Set",
      price: 52	,
      lastPrice :70,
      image:
      kidChair22,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 41,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "These are lightweight, yet strong and sturdy, and their natural flexibility provides a comfortable and supportive seat for your child. Additionally, their unique texture and natural finish add a touch of elegance to any room. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Organic Rattan Kids Chair Ottoman Set",
      price: 50	,
      lastPrice :73,
      image:
      kidChair23,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 61,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This Chair Ottoman set  not only ensures your children's comfort and safety, but it also promotes sustainable living practices. Give your children a head start in their eco-friendly journey with this beautiful and practical set.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Sustainable Sprout Seat Red Rattan Chair",
      price: 28	,
      lastPrice :38,
      image:
      kidChair24,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 85,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This red elegant chair is not only stylish but also very durable, making it ideal for your child's everyday use. It is  lightweight and easy to move, allowing your child to move it around and play comfortably.",
      category: "Chairs2",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Scandi Small Seat Rattan Kids Chair Set",
      price: 50	,
      lastPrice :75,
      image:
      kidChair25,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "These are lightweight, yet strong and sturdy, and their natural flexibility provides a comfortable and supportive seat for your child. Additionally, their unique texture and natural finish add a touch of elegance to any room. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Tiny Treasures Rattan Toddler Outdoor Chair Table Set",
      price: 46	,
      lastPrice :60,
      image:
      kidChair26,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 85,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "The round rattan table come with comfortable chairs to ensure your little ones can sit and play for hours on end. The accompanying table is perfect for snacks, drawing, or reading.This set is not only stylish but also very durable, making it ideal for your child's everyday use. It is  lightweight and easy to move, allowing your child to move it around and play comfortably.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Eco Baby Throne Rattan Patio Kids Chair",
      price: 26	,
      lastPrice :35,
      image:
      kidChair27,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 75,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        "This Multicolor elegant chair is not only stylish but also very durable, making it ideal for your child's everyday use. It is  lightweight and easy to move, allowing your child to move it around and play comfortably. The accompanying table is perfect for snacks, drawing, or reading.This set is not only stylish but also very durable, making it ideal for your child's everyday use. It is  lightweight and easy to move, allowing your child to move it around and play comfortably.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Rustic Cane Cruiser Kids Garden Chairs Table Set ",
      price: 92	,
      lastPrice :135,
      image:
      kidChair28,
      colors: ["#000"],
      company: "Ecowoodies",
      reviews: 75,
      featured: false,
      feature1:"100 %Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Sarkanda, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Baby Chair",
      description:
        " Our Bamboo Cane Kids Chairs Set with Table not only ensures your children's comfort and safety, but it also promotes sustainable living practices. Give your children a head start in their eco-friendly journey with this beautiful and practical set.",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handmade Bamboo Lantern Lamp",
      price: 13,
      lastPrice :19,
      image:
      lamp1,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 92,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Antique",
      description:
        "Our Bamboo Lantern Lamps come in a variety of shapes and sizes, making them suitable for use in a range of settings, from living rooms and dining areas to cafes and restaurants.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handcrafted  Bamboo Fabric Table Lamp",
      price: 16,
      lastPrice :23,
      image:
      lamp2,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 102,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " Our bamboo rattan table lamps offer a unique combination of sustainability, durability, natural beauty, and versatility that make them a great choice for anyone looking to add a touch of warmth and character to their home or business.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Incandescent Modern/Contemporary Bamboo Table Lamp",
      price: 17,
      lastPrice :24,
      image:
      lamp3,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:98,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " Transform your home into a sustainable oasis with our handmade bamboo rattan  table lamps, crafted with care by local artisans from Assam, Tripura, and Nagaland. Made from eco-friendly and recyclable bamboo and rattan, these lamps are the perfect choice for those seeking an environmentally conscious lighting solution. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Neoteric Bamboo Floor Lamp",
      price: 20,
      lastPrice :26,
      image:
      lamp4,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:112,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These are made  from eco-friendly and recyclable bamboo and rattan, these lamps are the perfect choice for those seeking an environmentally conscious lighting solution. Transform your home into a sustainable oasis with our handmade bamboo rattan floor lamps and table lamps, crafted with care by local artisans from Assam, Tripura, and Nagaland.  ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Classic Handmade Bamboo Floor Lamp",
      price: 22	,
      lastPrice :27,
      image:
      lamp5,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:132,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " If you're looking to brighten up your living space, bedroom, or office, our range of handcrafted lighting options will bring a touch of rustic charm and elegance to your decor. Choose our bamboo rattan floor lamps for a sustainable and stylish addition to your home or business. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Burnt Spice Bamboo Table Lamp",
      price: 18,
      lastPrice :25,
      image:
      lamp6,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:118,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " The natural look of this rattan bamboo lamp adds a warm and cozy touch to any space.It can be used to add a natural touch to outdoor spaces like patios, gardens, or balconies. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Patiofy Bamboo Rustic Table Lamp",
      price: 19,
      lastPrice :25,
      image:
      lamp7,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:107,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "  These table lamps emit a soft and warm glow, creating a cozy and inviting atmosphere that's perfect for relaxation or entertainment.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Hollowshade Bamboo Table Lamp",
      price: 17,
      lastPrice :23,
      image:
      lamp8,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:138,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " These lamps not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handmade Bamboo Column Lamp",
      price: 20,
      lastPrice :27,
      image:
      lamp9,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:98,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " Our Bamboo rattan table lamps  come in a variety of shapes and sizes, making them suitable for use in a range of settings, from living rooms and bedrooms to offices and restaurants.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handwoven Rattan Chandelier Lamp",
      price: 26,
      lastPrice :35,
      image:
      lamp10,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " Our Bamboo rattan chandeliers are beautifully crafted and showcase the natural texture and warmth of these materials, lending a rustic and elegant feel to any room.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Natural Glow Rattan Hanging Lamp",
      price: 19,
      lastPrice :25,
      image:
      lamp11,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Our these bamboo rattan chandelier ceiling hanging lamps come in various sizes and shapes, making them suitable for use in different settings such as living rooms, dining rooms, and bedrooms.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Bamboo Wicker Rattan Shade Decorative Wall Lamp",
      price: 19,
      lastPrice :25,
      image:
      lamp12,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Our Bamboo rattan wall decorative lamps are an excellent way to incorporate sustainable and eco-friendly products into your interior design scheme.Each lamp is handcrafted by local artisans, showcasing the unique skills and craftsmanship of these talented individuals. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Folks Handcrafted Designer Bamboo Table Lamp",
      price: 22,
      lastPrice :28,
      image:
      lamp13,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These Bamboo designer lamps emit a soft and warm glow, creating a cozy and inviting atmosphere that's perfect for relaxation or entertainment. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Bamboo Rustic Chandelier Decorative Lamps",
      price: 24,
      lastPrice :30,
      image:
      lamp14,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Our Bamboo rattan hanging decorative lamps not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design.Each lamp is handcrafted by local artisans, showcasing the unique skills and craftsmanship of these talented individuals. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Vintage Swivel Hanging Sconce Lamp",
      price: 19,
      lastPrice :25,
      image:
      lamp15,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:82,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "The natural texture and warmth of rattan lend a rustic elegance to these lamps, making them a beautiful addition to any interior space. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Bamboo Chandelier Pendant Lamp",
      price: 20,
      lastPrice :26,
      image:
      lamp16,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These lamps emit a soft and warm glow, creating a cozy and inviting atmosphere that's perfect for relaxation or entertaining.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Cane Rattan Ceiling Rustic Pendant Lamp",
      price: 18,
      lastPrice :27,
      image:
      lamp17,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " If you're looking to brighten up your living space, bedroom, or office, our range of handcrafted lighting options will bring a touch of rustic charm and elegance to your decor.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Bamboo Hanging Black Pendant Lamp",
      price: 14,
      lastPrice :23,
      image:
      lamp18,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:66,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "This Bamboo hanging decorative lamp is an excellent way to incorporate sustainable and eco-friendly products into your interior design scheme.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Handmade Classic Bamboo Lighten Lamp ",
      price: 13,
      lastPrice :19,
      image:
      lamp19,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:55,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Classic",
      description:
        "If you are looking for classic bamboo lantern lamp , this lamp is antique , lovingly handmade by local artisans from Assam, Tripura, and Nagaland, showcasing the unique craftsmanship and skill of these talented individuals.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Vintage Chic Bamboo Rattan Ceiling Lamp ",
      price: 18,
      lastPrice :24,
      image:
      lamp20,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:55,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These rattan bamboo lamps are heat-resistant and do not become hot to the touch, making them safe to use in any room.The warm glow of rattan bamboo lamps creates a relaxing and comfortable atmosphere in any room. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Bamboo Wall Pendant Lamp ",
      price: 17,
      lastPrice :23,
      image:
      lamp21,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:52,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These lamps emit a soft and warm glow, creating a cozy and inviting atmosphere that's perfect for relaxation or entertainment.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Midcentury Bamboo Table Lamp ",
      price: 20,
      lastPrice :26,
      image:
      lamp22,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:52,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "This unique multicolor bamboo table  is an excellent way to incorporate sustainable and eco-friendly product into your interior design scheme.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Antique Light Brown Bamboo Table Lamp ",
      price: 18,
      lastPrice :26,
      image:
      lamp23,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:88,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Antique",
      description:
        "These antique bamboo table lamps are not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design. These lamps are an excellent way to incorporate sustainable and eco-friendly product into your interior design scheme.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Unique Rattana Webbing Bamboo Table Lamp ",
      price: 20,
      lastPrice :28,
      image:
      lamp24,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:88,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Antique",
      description:
        "This beige lamp not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Modern Cylinderical Bamboo Wall Lamps",
      price: 26,
      lastPrice :38,
      image:
      lamp25,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:93,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "These Ecowoodies Bamboo wall lamps are beautifully crafted and showcase the natural texture and warmth of these materials, lending a rustic and elegant feel to any room.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: " Nordic Style Bamboo Decorative Lamp",
      price: 24,
      lastPrice :35,
      image:
      lamp26,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:103,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " Our bamboo rattan decorative lamps offer a unique combination of sustainability, durability, natural beauty, and versatility that make them a great choice for anyone looking to add a touch of warmth and character to their space. These lamps not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handmade Decorative Bamboo Floor Lamp",
      price: 21,
      lastPrice :28,
      image:
      lamp27,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:78,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Transform your home into a sustainable oasis with our handmade bamboo floor lamps and table lamps, crafted with care by local artisans from Assam, Tripura, and Nagaland. Made from eco-friendly and recyclable bamboo and rattan, these lamps are the perfect choice for those seeking an environmentally conscious lighting solution. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Retro Vintage Cane Rattan Hanging Chandelier Lamp",
      price: 26,
      lastPrice :39,
      image:
      lamp28,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:63,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Illuminate your home with our decorative hanging rattan cane lamp ,these lamps not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Dome Sconce Rattan Webbing Ceiling Lamp",
      price: 20,
      lastPrice :29,
      image:
      lamp29,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:63,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Our bamboo rattan ceiling hanging lamps come in various sizes and shapes, making them suitable for use in different settings such as living rooms, dining rooms, and bedrooms. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Rustic Home Lighing Rattan Hanging Lamp ",
      price: 20,
      lastPrice :28,
      image:
      lamp30,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 109,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " This lamp is a unique work of art, showcasing the natural textures and beauty of these sustainable materials. Our floor lamps and table lamps offer a warm and inviting glow, creating a cozy and relaxed atmosphere in any room.  ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Handcrafted Webbing Wicker Hanging Dome Lamp",
      price: 19,
      lastPrice :28,
      image:
      lamp31,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " If you are looking to brighten up your living space, bedroom, or office, our range of handcrafted lighting options will bring a touch of rustic charm and elegance to your decor.  ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Rattan Weave Bamboo Floor Lamp",
      price: 24,
      lastPrice :31,
      image:
      lamp32,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews: 89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " This bamboo rattan floor  lamps offer a unique combination of sustainability, durability, natural beauty, and versatility that make them a great choice for anyone looking to add a touch of warmth and character to their home or business. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Retro Handwoven Rattan Wall Lamp",
      price: 19,
      lastPrice :28,
      image:
      lamp33,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:92,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " This rattan webbing lamp is unique in its design, adding a touch of rustic elegance to any interior space.  ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Rattan Round Ceiling Sconce Pendant Lamp",
      price: 16,
      lastPrice :24,
      image:
      lamp34,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:72,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " This rattan handmade lamp not only offer an eco-friendly lighting solution, but they also showcase the intricate craftsmanship of our talented artisans.   ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Decorative Hanging Lantern Lamp",
      price: 21,
      lastPrice :29,
      image:
      lamp35,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:102,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " If you are looking to brighten up your living space, bedroom, or office, our range of handcrafted lighting options will bring a touch of rustic charm and elegance to your decor.   ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Mini Gold Bamboo Torchiere  Wall Lamp",
      price: 14,
      lastPrice :19,
      image:
      lamp36,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        " If you are looking to brighten up your room bring the warmth and character of ecowoodies' natural materials into your home with our range of handmade furniture and decor, crafted with care and expertise from the heart of India.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Rustic Black Bamboo Chandelier Hanging Lamps",
      price: 28,
      lastPrice :39,
      image:
      lamp37,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "Illuminate your home with our decorative hanging rattan chandelier lamps ,these lamps not only provide functional lighting but also serve as a beautiful and sustainable decor piece that can elevate any interior design. ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Rustic Black Rattan Decoratvie Hanging Lamp",
      price: 22,
      lastPrice :33,
      image:
      lamp38,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      reviews:62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Modern",
      description:
        "This lamp is a unique work of art, showcasing the natural textures and beauty of these sustainable materials. Our floor lamps and table lamps offer a warm and inviting glow, creating a cozy and relaxed atmosphere in any room.  ",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "CaneBloom Sofa Couch",
      price: 46,
      lastPrice :65,
      image:
       sofaSet6,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 32,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "This lightweight cane sofa couch is a veraslite option for your living space. Our cane rattan sofa couches come in a variety of designs and styles, from minimalist to intricate patterns, so you can find the perfect match for your interior design.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Natural Rattan Beige Sofas With Chairs and Table",
      price: 190,
      lastPrice :260,
      image:
       sofaSet7,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 43,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "Our these bamboo rattan sofa sets are comfortable to sit on and offer good support for your back. It is resistant to scratches, water, and sunlight, making it ideal for outdoor and indoor use.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Oraganic Cane 5 Seater Bamboo Sofa",
      price: 250,
      lastPrice :320,
      image:
       sofaSet8,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 39,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "This Sofa Set is  designed with ergonomics in mind, providing you with a comfortable seating experience.Its clean lines and simple design allow it to blend seamlessly with your existing decor, while its sturdy construction ensures it will stand the test of time. Upgrade your living room with our stylish and sustainable Cane 5 Seater Sofa Set. Order yours today and experience the beauty and quality of handmade furniture from North India. ",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Rattan Heaven Multicolor Sofa Couch",
      price: 48,
      lastPrice :60,
      image:
       sofaSet9,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 39,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "This bamboo rattan sofa couch is not only environmentally friendly, but also chic and comfortable, perfect for lounging with family and friends. ",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Eco Lounge Rattan Sofa Chairs With Table",
      price: 170,
      lastPrice :220,
      image:
       sofaSet10,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 89,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Cane, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "These bamboo rattan sofachairs have a natural and rustic look that can add warmth and character to your home décor. They come in a variety of designs and styles, from minimalist to intricate patterns, so you can find the perfect match for your interior design.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Bambusa 5 Seater Wicker Sofa Set ",
      price: 280,
      lastPrice :360,
      image:
       sofaSet11,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 89,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Cane, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "Transform your living room with our handcrafted Cane 5 Seater Sofa Set. The set's unique design and durable construction are sure to impress, while its eco-friendly materials and ethical production practices make it a responsible choice for your home. Crafted from high-quality cane, this sofa set is strong and built to last. The natural texture and warm color of the cane lend it a timeless elegance that complements any decor style. Plus, because it's handmade, each sofa is one-of-a-kind, adding a touch of artistry to your living space. As a furniture manufacturing company that values sustainability, we're committed to using only responsibly sourced materials and ethical production practices. We partner with local artisans to ensure that our furniture is made with care and attention to detail, while also supporting the livelihoods of skilled craftspeople. The Cane 5 Seater Sofa Set is perfect for families and socializing, with plenty of room for everyone to relax in comfort. Bamboo cane rattan sofa sets are often handmade by local artisans, making each piece unique and special. ",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recNZ0koOqEmilmoz",
      name: "Rattan Breeze 8 Seater Sofa Set ",
      price: 230,
      lastPrice :300,
      image:
       sofaSet12,
      colors: ["#000", "#ff0000"],
      company: "Ecowoodies",
      reviews: 64,
      featured:false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo,Cane, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Hotels And office",
      
      description:
        "This bamboo rattan sofa sets offer sustainability, durability, comfort, lightweight, handmade craftsmanship, and a natural aesthetic that can enhance any living space.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Rattan Wicker Storage Boxes",
      price: 82,
      lastPrice :108,
      image:
      storageBox6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 28,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These Large and Medium size bamboo rattan storage boxes have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. It can be used for a variety of purposes, including as a coffee table, storage ottoman, or as a decorative accent in any room of the house. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Comfort Cane Storage Rack",
      price: 49,
      lastPrice :60,
      image:
      shelve1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 78,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Compact Storage Solution to Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These bamboo shelves are handmade from natural and durable bamboo cane , making them not only eco-friendly and recyclable but also strong and long-lasting. They are carefully crafted by local artisans who have inherited the skills and traditions of bamboo weaving and furniture making from their ancestors. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo 3 Shelves Storage Rack",
      price: 48,
      lastPrice :57,
      image:
      shelve2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 71,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These bamboo shelves utility rack storage organisers are not only practical storage solutions, but they are also works of art that showcase the beauty and versatility of bamboo. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Corner Triangular Cane Decorative Utility Rack ",
      price: 53,
      lastPrice :68,
      image:
      shelve3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " This corner display unit can be used to showcase a piece of artwork, such as a sculpture or painting, that would otherwise go unnoticed in a room. These decorative corner utility racks shelves can be used in various rooms of your home, including entryways, living rooms, bedrooms, and closets. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Rattan 3 Shelves Multi Utility Shoe Rack ",
      price: 46,
      lastPrice :60,
      image:
      shelve4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 109,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " Our bamboo rattan  shoe racks utility racks shelves are handmade by skilled artisans, giving them a unique and artisanal touch. They provide a stylish and functional way to organise your shoes, books, plants, and other essentials. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Rattan 3 Tier Shelves Storage Rack",
      price: 47,
      lastPrice :58,
      image:
      shelve5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 112,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " Ecowoodies bamboo rattan racks shelves are aesthetically pleasing, adding a touch of natural beauty and warmth to any room.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Cane Cottage Book Storage Utility Rack ",
      price: 50,
      lastPrice :63,
      image:
      shelve6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 112,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        "This storage cane utility rack is durable, strong and can withstand regular use and wear.They provide a stylish and functional way to organise your shoes, books, plants, and other essentials. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Shelves Multi Purpose Organizer Rack ",
      price: 50,
      lastPrice :62,
      image:
      shelve7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 142,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " This Multi purpose bamboo racks utility racks shelves are sustainable, eco-friendly, and contribute to a more eco-conscious lifestyle.They provide a stylish and functional way to organise your shoes, books, plants, and other essentials.  ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Cane Carft Storage Utility Rack ",
      price: 39,
      lastPrice :48,
      image:
      shelve8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 84,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These handcrafted cane storage racks utility racks shelves can be used in various rooms of your home, including entryways, living rooms, bedrooms, and closets. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handcrafted Cane Display Unit ",
      price: 40,
      lastPrice :57,
      image:
      shelve9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 142,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " This well-designed handcrafted corner cane display unit can add visual interest to a room and create a focal point that draws the eye. These units are lightweight, making them easy to move around and reposition.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handmade Designer Cane Decorative Wall Unit ",
      price: 55,
      lastPrice :70,
      image:
      shelve10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These oval shape display wall units are an ideal way to showcase a collection of items, such as figurines, plates, or other decorative objects. These units are sustainable, eco-friendly, and contribute to a more eco-conscious lifestyle.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Vintage Cane Cozy Bamboo Rack  ",
      price: 58,
      lastPrice :75,
      image:
      shelve11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 83,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        "  These designer racks are often used in retail settings to showcase products or merchandise.These units are sustainable, eco-friendly, and contribute to a more eco-conscious lifestyle.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Rattan Multi Utility 4 Shelves Unit  ",
      price: 49,
      lastPrice :60,
      image:
      shelve12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 95,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Large and Medium size bamboo rattan storage racks and  boxes have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. It can be used for a variety of purposes or as a decorative accent in any room of the house. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Handwoven Rack and Storage Box  ",
      price: 72,
      lastPrice :85,
      image:
      shelve13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Large and Medium size bamboo rattan storage boxes have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. It can be used for a variety of purposes, including as a coffee table, storage ottoman, or as a decorative accent in any room of the house. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Wicker Vertical Storage Rack  ",
      price: 50,
      lastPrice :61,
      image:
      shelve14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 115,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These bamboo  utility racks shelves are moisture-resistant, making them perfect for storing shoes and other items that can be damaged by moisture. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Mid-Century Bamboo Decorative Display Unit  ",
      price: 57,
      lastPrice :70,
      image:
      shelve15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 93,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These designer bamboo corner display units come with shelves , making them a practical storage solution for books, DVDs, or other small items.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Corridor Decor Corner Cane Utility Rack ",
      price: 50,
      lastPrice :62,
      image:
      shelve16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These well-designed corner display units can add visual interest to a room and create a focal point that draws the eye. These decorative racks shelves are handmade by local artisans, supporting traditional craftsmanship and their communities. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Bamboo Rattan 3 Shelves Rack ",
      price: 50,
      lastPrice :62,
      image:
      shelve17,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 118,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Ecowoodies bamboo rattan shelves utility racks are sustainable, eco-friendly, and contribute to a more eco-conscious lifestyle.",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Contemporary Book Shelves Multi Purpose Utility Bamboo Rack ",
      price: 49,
      lastPrice :60,
      image:
      shelve18,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 93,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Compact Storage Solution to Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige bamboo rattan storage shelve utility racks are lightweight, making them easy to move around and reposition. These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Shelves",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Outdoor Rattan Table ",
      price: 48,
      lastPrice :63,
      image:
      table1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 93,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige bamboo rattan tables are suitable for both indoor and outdoor spaces and can be used in homes, offices, and other commercial spaces. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Bamboo Rattan Table ",
      price: 50,
      lastPrice :65,
      image:
      table2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 123,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Bamboo rattan tables are also highly durable and can last for many years with proper care. They are also versatile and can be used in various settings and styles, from modern to traditional.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Modern Bamboo Center Table  ",
      price: 62,
      lastPrice :85,
      image:
      table3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 103,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " If you are looking for decorative durable table, this brown bamboo table is good choice, handmade by skilled artisans from Assam, Tripura, and Nagaland, each pece is carefully crafted to highlight the natural beauty of bamboo.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Wicker Heaven Rattan Coffee Table",
      price: 55,
      lastPrice :75,
      image:
      table4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 98,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige bamboo rattan tables are lightweight, making them easy to move around and reposition. These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Lowheight Wicker Table",
      price: 35,
      lastPrice :47,
      image:
      table5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 92,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " If you are looking for a unique conversation starter or a practical piece to anchor your living room, our bamboo furniture is the perfect choice. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Bamboo Bliss Table ",
      price: 55,
      lastPrice :67,
      image:
      table6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 127,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " They are handmade sustainable and eco-freindly , these can also be used to display decorative items such as flowers, candles, or art pieces. This can add visual interest and personality to the room.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Organic Bamboo Center Table ",
      price: 65,
      lastPrice :77,
      image:
      table7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 120,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Bamboo tables are also highly durable and can last for many years with proper care. They are also versatile and can be used in various settings and styles, from modern to traditional.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Patio Coffee Table ",
      price: 63,
      lastPrice :75,
      image:
      table8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 120,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These bamboo tables have a unique natural aesthetic that adds character to any space.They are affordable compared to other types of furniture and are available in various styles and designs. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Bamboo Center Table ",
      price: 73,
      lastPrice :95,
      image:
      table9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " If you are looking for highly classy durable center table , this is the unique design can be used in various settings and styles, from modern to traditional.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Bamboo Square Coffee Table ",
      price: 43,
      lastPrice :55,
      image:
      table10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 152,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " It is  low-height table which is designed to be used for serving tea, snacks, or other small items.The compact size of these tables means they take up very little space, making them ideal for smaller rooms or apartments. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmakers Bamboo Tea Table  ",
      price: 42,
      lastPrice :55,
      image:
      table11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 202,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        "  These are typically low-height tables that are designed to be used for serving tea, snacks, or other small items. These are also  lightweight, making them easy to move around and reposition. These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Round Cane Table ",
      price: 48,
      lastPrice :60,
      image:
      table12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 232,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These cane decorativs tea tables have a natural and simple aesthetic that can fit well with many different decor styles. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Round Bamboo Coffee Table ",
      price: 45,
      lastPrice :58,
      image:
      table13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 172,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        "These small bamboo tea tables are sustainable,  decorative  and easy to move, which makes them great for using in different parts of the house or even outdoors. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Beige Natural Rattan Bamboo Table ",
      price: 58,
      lastPrice :72,
      image:
      table14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 138,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige rattan table has a natural, organic look that can add warmth and texture to a room.These Large rattan tables can be used for a variety of purposes, such as serving as a dining table, a coffee table, or even a work table.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Cane Art Coffee Table",
      price: 52,
      lastPrice :70,
      image:
      table15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 156,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These small decorative designer round cane tables are a versatile, sustainable, and aesthetically pleasing piece of furniture that can be used in a variety of settings.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Cane Decortaive Side Table",
      price: 45,
      lastPrice :56,
      image:
      table16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 156,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " This bamboo  table can be used to display decorative items such as flowers, candles, or art pieces. This can add visual interest and personality to the room. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Stylish Round Rattan Cane Table",
      price: 45,
      lastPrice :55,
      image:
      table17,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 106,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These small decorative designer round cane tables are a versatile, sustainable, and aesthetically pleasing piece of furniture that can be used in a variety of settings.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Modern Cane Unique Coffee Table",
      price: 48,
      lastPrice :57,
      image:
      table18,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 178,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " This round cane table can serve as the centerpiece of a room, drawing attention and providing a visual anchor for the surrounding furniture and decor.These small decorative designer round cane tables are a versatile, sustainable, and aesthetically pleasing piece of furniture that can be used in a variety of settings.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Round Bamboo Coffee Table",
      price: 45,
      lastPrice :55,
      image:
      table19,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 225,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige cane rattan lowheight center tables are decorative and  lightweight, making them easy to move around and reposition. These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Rattan Bamboo Round Table ",
      price: 43,
      lastPrice :52,
      image:
      table20,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 135,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These bamboo rattan tables are versatile and can be used in various ways, including as center tables, coffee tables, corner tables, and decorative tables. They are suitable for both indoor and outdoor spaces and can be used in homes, offices, and other commercial spaces.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Rectangular Rattan Bamboo Table ",
      price: 56,
      lastPrice :68,
      image:
      table21,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 135,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These beige bamboo rattan tables can be used to hold drinks and snacks when entertaining guests or relaxing in front of the television.These are durable and lightweight, making them easy to move around and reposition. These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Bamboo Wicker Table ",
      price: 48,
      lastPrice :58,
      image:
      table22,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 115,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These bamboo rattan tables are versatile and can be used in various ways, including as center tables, coffee tables, corner tables, and decorative tables. They are suitable for both indoor and outdoor spaces and can be used in homes, offices, and other commercial spaces.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Bamboo Center Storage Table ",
      price: 58,
      lastPrice :70,
      image:
      table23,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 138,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These center tables come with storage space that can be used to store items such as magazines, books, or remote controls. These large rattan tables have a natural, organic look that can add warmth and texture to a room  ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Wicker Bamboo Table ",
      price: 56,
      lastPrice :70,
      image:
      table24,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 109,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        "Despite their size, large rattan tables are relatively lightweight, which makes them easy to move around or rearrange in a room. These large rattan tables are a versatile and attractive piece of furniture that can be used in a variety of settings. They are durable, lightweight, and easy to clean, and they have a natural look that can complement many different decor styles. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Sustainable Home Rattan Bamboo Table ",
      price: 54,
      lastPrice :70,
      image:
      table25,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These tables are durable, lightweight, and easy to clean, and they have a natural look that can complement many different decor styles.These are handmade by skilled artisans, giving them a unique and artisanal touch. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Craft Rattan Storage Table",
      price: 62,
      lastPrice :75,
      image:
      table26,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 153,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Garden, Study Room, Bedroom, Office, Hotel, Restaurant ",
      description:
        " These Large rattan tables have a natural, organic look that can add warmth and texture to a room and can be used for a variety of purposes, such as serving as a dining table, a coffee table, or even a work table. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Wonders Rattan Coffee Table Ottoman Set",
      price: 110,
      lastPrice :150,
      image:
      stoolTable1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " If you are looking for a coffee table or an ottoman table, our bamboo rattan wicker pieces are versatile and adaptable to any decor. With their natural materials and timeless design, they're sure to become a cherished part of your home for years to come. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Coastal Comfort Rattan Coffee Table Set",
      price: 108,
      lastPrice :160,
      image:
      stoolTable2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 124,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Iron",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " These rattan ottomans table have a natural, organic look that can add warmth and texture to a room . They can be used in a variety of ways, depending on your needs and preferences. They can also be used as coffee tables, side tables, footstools, or extra seating. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Wonders Rattan Coffee Table Ottoman Set",
      price: 118,
      lastPrice :165,
      image:
      stoolTable3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 186,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " Our bamboo rattan table ottoman sets are a beautiful and practical choice for any home. They offer durability, sustainability, affordability, and versatility, making them a great investment for any homeowner looking to add natural elegance to their living space. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Hues Bamboo Outdoor Table Ottoman Set",
      price: 125,
      lastPrice :168,
      image:
      stoolTable4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 186,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " These  rattan bamboo table ottoman set  is lightweight and easy to move around, making it a versatile choice for any living space.These large rattan tables have a natural, organic look that can add warmth and texture to a room and can be used for a variety of purposes. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Sustainable Seating Rattan Ottoman Table Set",
      price: 112,
      lastPrice :150,
      image:
      stoolTable5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 106,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " These Coffee table ottoman sets can also be used as decorative accents in a room, adding texture and interest to a space. With their soft, cushioned surfaces and intricate designs, ottomans can add a cozy and inviting touch to any room. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Handcrafted Cane Ottoman Table Set",
      price: 140,
      lastPrice :180,
      image:
      stoolTable6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 179,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " This coffee table ottoman set can be used as a traditional coffee table to hold drinks, snacks, or books. The flat surface of the table provides a stable place to put objects, while the ottomans can be pulled out and used as extra seating.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handcrafted Patio Ottoman Table Set",
      price: 130,
      lastPrice :170,
      image:
      stoolTable7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 142,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " If you are looking for a coffee table or an ottoman table, our bamboo rattan wicker pieces are versatile and adaptable to any decor. With their natural materials and timeless design, they're sure to become a cherished part of your home for years to come.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Rattan Bamboo Table Ottoman Set",
      price: 135,
      lastPrice :190,
      image:
      stoolTable8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 139,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " These bamboo rattan table ottoman sets are a beautiful and practical choice for any home. They offer durability, sustainability, affordability, and versatility, making them a great investment for any homeowner looking to add natural elegance to their living space.  ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Hand-Woven Rattan Bamboo Black Table Ottoman Set",
      price: 120,
      lastPrice :170,
      image:
      stoolTable9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 151,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        "These  table ottoman sets can be versatile and practical pieces of furniture that can serve multiple uses, making them a great investment for any home.These rattan ottomans table have a natural, organic look that can add warmth and texture to a room . They can be used in a variety of ways, depending on your needs and preferences. They can also be used as coffee tables, side tables, footstools, or extra seating.",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Hand-Woven Rattan Bamboo Red Ottoman Table Set",
      price: 120,
      lastPrice :170,
      image:
      stoolTable10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        "One of the main feature of this  rattan tables ottoman sets is their aesthetic appeal. Rattan has a natural and timeless beauty that can enhance any decor style. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Oasis Rattan Table Ottoman Set ",
      price: 150,
      lastPrice :165,
      image:
      stoolTable11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " Ecowoodies bamboo rattan table ottoman sets are a beautiful and practical choice for any home. They offer durability, sustainability, affordability, and versatility, making them a great investment for any homeowner looking to add natural elegance to their living space. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Elegance Rattan Wicker Table Ottoman Set ",
      price: 180,
      lastPrice :225,
      image:
      stoolTable12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 163,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Garden, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " These  rattan bamboo table ottoman set  is lightweight and easy to move around, making it a versatile choice for any living space.These large rattan tables have a natural, organic look that can add warmth and texture to a room and can be used for a variety of purposes, such as serving as a dining table, a coffee table, or even a work table. ",
      category: "Tables",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Bliss Rattan Cane Chair",
      price: 28,
      lastPrice :37,
      image:
      chair1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 223,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant ",
      description:
        " If you are looking for recliner relaxing chairs,these rattan cane are a stylish and eco-friendly alternative to traditional recliner chairs, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during relaxation and lounging.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Sustainable Bamboo Wooden Director Chair",
      price: 42,
      lastPrice :50,
      image:
      chair2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 207,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " It enhance the aesthetic appeal of an office, adding a natural and organic touch. This bamboo rattan chair is resistant to scratches, dents, and other forms of wear and tear, making them suitable for heavy use in office environments.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Wingback Cane Chair",
      price:32,
      lastPrice :40,
      image:
      chair3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 186,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane , Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " The rattan and cane seat of this handwoven chair provide a comfortable and supportive surface that conforms to the body's shape.these rattan cane are a stylish and eco-friendly alternative to traditional recliner chairs, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during relaxation and lounging. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Bamboo Rustic Cane Armchair",
      price:42,
      lastPrice :52,
      image:
      chair4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 96,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Cane, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Hotel, Restaurant  ",
      description:
        " These cane chairs enhance the visual appeal of a room or space.These chairs are versatile and can complement a variety of decorating styles, from bohemian to tropical and modern. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Chic Bamboo Cane Armchair",
      price:42,
      lastPrice :52,
      image:
      chair5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 92,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Hotel, Restaurant  ",
      description:
        " These cane chairs have a unique and exotic look that can add character to any space.These chairs are versatile and can complement a variety of decorating styles, from bohemian to tropical and modern. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Cane Peacock Armchair",
      price:48,
      lastPrice :62,
      image:
      chair6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 72,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Farmhouse, Hotel, Restaurant  ",
      description:
        " These designer chairs have a unique and natural aesthetic that can add warmth and texture to any dining room or living room.These chairs are versatile and can complement a variety of decorating styles, from bohemian to tropical and modern. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Organic Elegance Bamboo Chair",
      price:48,
      lastPrice :60,
      image:
      chair7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 112,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These chairs are made from bamboo cane, which is a type of bamboo that has a hollow core and a hard exterior.These chairs have a distinctive design that features a high backrest and a fan-shaped backrest that resembles a peacock's tail.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Weave Rattan Desk Chair",
      price:26,
      lastPrice :35,
      image:
      chair8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 192,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This rattan chair is lightweight and easy to move around, making them ideal for outdoor spaces such as patios, gardens, and decks.This can be used as standalone chairs or paired with other outdoor furniture pieces such as tables, couches, or hammocks. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Breeze Bamboo Conference Chair",
      price:40,
      lastPrice :50,
      image:
      chair9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 102,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These bamboo rattan office chairs are a stylish and eco-friendly alternative to traditional office chairs, offering comfort, durability, and unique aesthetic appeal.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Charm Rattan Bamboo Chair",
      price:41,
      lastPrice :55,
      image:
      chair10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 102,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Iron",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "These bamboo dining chairs are used as seating around a dining table, providing a comfortable and stylish place to sit while enjoying meals with family and friends.They can also be used as accent chairs in other areas of the home, such as the living room or bedroom.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Dreams Bamboo Chair",
      price:48,
      lastPrice :60,
      image:
      chair11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 126,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This chair has a unique and natural aesthetic that can add warmth and texture to any room or office. This chairs is built to last and can withstand frequent use. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Mid-Century Cottage Cane Rattan Chair",
      price:55,
      lastPrice :70,
      image:
      chair12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 198,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "  If you are looking for recliner relaxing chairs,these rattan cane are a stylish and eco-friendly alternative to traditional recliner chairs, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during relaxation and lounging.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Sustainable Swivel Bamboo Rattan Chair",
      price:32,
      lastPrice :40,
      image:
      chair13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 262,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This rattan chair is ideal for outdoor spaces such as patios, gardens, and decks.This can be used as standalone chairs or paired with other outdoor furniture pieces such as tables, couches, or hammocks.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Organic Weave Bamboo Garden Chair Set",
      price:60,
      lastPrice :80,
      image:
      chair14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 262,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This rattan bamboo chair set  is lightweight and easy to move around, making it a versatile choice for any living space.These chairs have a natural, organic look that can add warmth and texture to a room or garden ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Garden Breeze Rattan Chair Set",
      price:65,
      lastPrice :80,
      image:
      chair15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 291,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These padded chairs provide extra comfort and support, reducing the risk of back pain and discomfort.These are lightweight and easy to move around, making them ideal for both indoor and outdoor use. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Artisanal Rattan Cushioned Patio Chairs",
      price:72,
      lastPrice :90,
      image:
      chair16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 345,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These rattan cushioned soft seat chairs are ideal for indoor or outdoor use, including in living rooms, dining rooms, patios, and gardens.Cushioned padded chairs provide a comfortable and supportive seating surface that conforms to the body's shape. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Ergonomic White Padded Patio Bamboo Chair",
      price:34,
      lastPrice :42,
      image:
      chair17,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 305,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "These padded chairs provide extra comfort and support, reducing the risk of back pain and discomfort.These are lightweight and easy to move around, making them ideal for both indoor and outdoor use.  ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Forest Patio Chair",
      price:42,
      lastPrice :53,
      image:
      chair18,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 118,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This bamboo chair is lightweight and easy to move around, making it a versatile choice for any living space.These have a natural, organic look that can add warmth and texture to a room and can be used for a variety of purposes. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rustic Bamboo Executive Wooden Chair",
      price:48,
      lastPrice :60,
      image:
      chair19,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 118,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This bamboo wooden chair is durable , lightweight and easy to move around, making it a versatile choice for any living space.Lightweight and easy to move around, making them ideal for offices with changing layouts. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Bamboo Dining Rattan Chair",
      price:34,
      lastPrice :45,
      image:
      chair20,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 328,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "  These hadnwoven patio rattan chair sets are a stylish and eco-friendly option for outdoor relaxation and lounging, resistant to scratches, dents, and other forms of wear and tear, making them suitable for heavy use during outdoor relaxation and lounging.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Soft Seating Rattan Egg Chair ",
      price:36,
      lastPrice :48,
      image:
      chair21,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 328,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " If you are looking comfortable chair , its hand woven rattan and bamboo seats provide a natural and breathable surface that conforms to the body's shape.It can be used as standalone chairs or paired with other outdoor furniture pieces such as tables, couches, or hammocks.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Deluxe Padding Cane Dining Chair ",
      price:52,
      lastPrice :66,
      image:
      chair22,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 289,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These designer chairs have a unique and natural aesthetic that can add warmth and texture to any dining room or living room.They can be customized with cushions and upholstery to match your decor. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Relaxation Recliner Cane Chair ",
      price:42,
      lastPrice :54,
      image:
      chair23,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 289,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " If you are looking for recliner relaxing chairs,these rattan cane are a stylish and eco-friendly alternative to traditional recliner chairs, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during relaxation and lounging.It can help to promote a sense of calm and relaxation during downtime. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Luxe Leather Padded Wooden Chairs",
      price:135,
      lastPrice :180,
      image:
      chair24,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 119,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Wood ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "  These chair have a unique and natural aesthetic that can add warmth and texture to any room or office. These chairs are built to last and can withstand frequent use. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Plush Padded Swivel Rattan Chairs",
      price:72,
      lastPrice :85,
      image:
      chair25,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 119,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This rattan bamboo chair set  is lightweight and easy to move around, making it a versatile choice for any living space.They are suitable for use in living rooms, bedrooms, patios, and outdoor spaces.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Cane Peacock Chair",
      price:48,
      lastPrice :60,
      image:
      chair26,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 427,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These chairs have a unique and exotic look that can add character to any space.They are durable and long-lasting because bamboo  cane is a strong and sturdy material.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Cane Lounge Chair",
      price:43,
      lastPrice :55,
      image:
      chair27,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 290,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "These chairs are  lightweight and easy to move around, making them ideal for outdoor and indoor spaces.The woven rattan and cane seats provide a comfortable and supportive surface that conforms to the body's shape. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Comfy Bamboo Cane Office Armless Office Chair",
      price:48,
      lastPrice :60,
      image:
      chair28,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 180,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "These are  lightweight and easy to move around, making them ideal for offices with changing layouts.It can help to promote a sense of calm and relaxation in a high-stress environment.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Woven Wicker Cozy Rattan Floor Egg Chair",
      price:28,
      lastPrice :36,
      image:
      chair29,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 376,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These chairs can be used as outdoor furniture because bamboo rattan  is a durable and weather-resistant material.They are suitable for use in living rooms, bedrooms, patios, and outdoor spaces. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Garden Chairs",
      price:62,
      lastPrice :78,
      image:
      chair30,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 452,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These hadnwoven patio rattan chair sets are a stylish and eco-friendly option for outdoor relaxation and lounging, resistant to scratches, dents, and other forms of wear and tear, making them suitable for heavy use during outdoor relaxation and lounging.These can be paired with other outdoor furniture pieces such as tables, couches, or hammocks. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Organic Elegance Cane Wicker Garden Chair",
      price:58,
      lastPrice :75,
      image:
      chair31,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 235,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " These chairs are a stylish and eco-friendly alternative to traditional recliner chairs, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during relaxation and lounging.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Comfort Bamboo Rattan Black Armchair",
      price:35,
      lastPrice :45,
      image:
      chair32,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 385,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This rattan chair is lightweight and easy to move around, making them ideal for outdoor spaces such as patios, gardens, and decks.This can be used as standalone chairs or paired with other outdoor furniture pieces such as tables, couches, or hammocks.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Bamboo Rattan Yoga Chair",
      price:48,
      lastPrice :60,
      image:
      chair33,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 441,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Farmhouse  ",
      description:
        " These yoga chairs can be used for seated poses, as well as for supporting the back and neck during other yoga poses.These are lightweight and easy to move around, making them ideal for personal practice spaces and group classes. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bistro Patio Cafe Chair",
      price:46,
      lastPrice :62,
      image:
      chair34,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 441,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " The natural texture and colors of this bamboo and rattan chair can vary, giving each chair a unique look and character. The woven rattan and bamboo seats provide a comfortable and supportive surface that conforms to the body's shape. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Sculptural Bamboo Rattan Meditation Chair",
      price:52,
      lastPrice :65,
      image:
      chair35,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 221,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Farmhouse  ",
      description:
        "These bamboo rattan yoga meditation chairs are a stylish and eco-friendly alternative to traditional meditation and yoga props, offering comfort, durability, and unique aesthetic appeal while supporting proper posture and alignment during meditation and yoga practices.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Floor Meditation Chair",
      price:50,
      lastPrice :63,
      image:
      chair36,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 623,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Farmhouse  ",
      description:
        "This rattan bamboo chair is comfortable, as the woven rattan and bamboo seats provide a natural and breathable surface that conforms to the body's shape. The design of this chair helps to support proper posture and alignment during meditation and yoga practices.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bistro Patio Garden Chair Ottoman Set",
      price:48,
      lastPrice :65,
      image:
      chair37,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 421,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " Our bamboo rattan chair ottoman set is a stylish and comfortable option for furnishing your living space. This set can be used as seating in a living room, bedroom, or garden.",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Rattan Chair Ottoman Set",
      price:108,
      lastPrice :135,
      image:
      chair38,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 391,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        " This bamboo rattan chair ottoman set has a unique and natural aesthetic that can add warmth and texture to any living space.The ottoman can be used as a footrest or as an additional seat when needed. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Handcrafted Rattan Chair Ottoman Set",
      price:118,
      lastPrice :140,
      image:
      chair39,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 340,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Lounge, Home, Living Room, Garden, Terrace, Courtyard, Beach, Pubs, Farmhouse, Office, Hotel, Restaurant  ",
      description:
        "Our bamboo rattan chair ottoman set is a beautiful and functional addition to any home. Its eco-friendly properties, lightweight and easy-to-move nature, and comfortable seating experience make it a popular choice for those who value sustainability and style. ",
      category: "Chairs2",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Indoor/Outdoor Generic Rattan Hanging chair",
      price: 110,
      lastPrice :147,
      image:
      hangingChair8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 74,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " It gives a touch of eco-friendly elegance to your home with our handmade bamboo rattan wicker hanging chair swing chair, crafted by local artisans in Assam, Tripura, and Nagaland. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Wicker Swing Chair",
      price: 108,
      lastPrice :135,
      image:
      hangingChair9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 56,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " It is perfect for indoor or outdoor use, our hanging chair swing chair is a cozy and comfortable addition to any space, providing a relaxing spot to read, meditate, or simply unwind. ",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Rattan Woven Egg Chair",
      price: 122,
      lastPrice :150,
      image:
      hangingChair10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 88,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " Its intricate woven design and natural, earthy tones, this chair is a true testament to the beauty of traditional craftsmanship. Perfect for any room in your home, our hanging chairs provide a cozy and relaxing seating option that will add a touch of natural beauty to your decor. They are great for indoor or outdoor use and can be easily hung from a sturdy beam or ceiling hook.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Garden Poly Bamboo Rattan Swing Chair",
      price: 130,
      lastPrice :160,
      image:
      hangingChair11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 79,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " This elegant rattan bamboo swing chair can be used both indoors and outdoors, making them versatile and practical.This can be hung from a sturdy ceiling or branch to create a cozy and relaxing spot in your home, garden, or patio.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Double Seater Hammock Swing Chair",
      price: 150,
      lastPrice :198,
      image:
      hangingChair12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 87,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " This double sitting swing chair  can be a great addition to any home or garden, providing a comfortable and stylish seating option that is both practical and environmentally friendly.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Coastal Cove Rattan Swing Seat",
      price: 96,
      lastPrice :127,
      image:
      hangingChair13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 108,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        "This stylish hanging ring chair can add a touch of natural and rustic charm to any space, creating a cozy and inviting atmosphere.Its comfortable and supportive design helps to  reduce stress and promote relaxation.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Hammock Hanging Chair",
      price: 98,
      lastPrice :130,
      image:
      hangingChair14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 110,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " These swing chairs can help save space and provide an alternative seating option, especially in small apartments or houses.Perfect for any room in your home, our hanging chairs provide a cozy and relaxing seating option that will add a touch of natural beauty to your decor. They are great for indoor or outdoor use and can be easily hung from a sturdy beam or ceiling hook.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Heaven Rattan Hanging Chair",
      price: 118,
      lastPrice :140,
      image:
      hangingChair15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 138,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " These are lightweight and durable, making them easy to move and transport.They can be hung from a sturdy ceiling or branch to create a cozy and relaxing spot in your home, garden, or patio.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Stylish Cane Porch Hanging Chair",
      price: 109,
      lastPrice :127,
      image:
      hangingChair16,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 38,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Garden, Couryard, Hotel, Restaurant ",
      description:
        " Perfect for any room in your home, our hanging chairs provide a cozy and relaxing seating option that will add a touch of natural beauty to your decor.  Its intricate woven design and natural, earthy tones, this chair is a true testament to the beauty of traditional craftsmanship. Perfect for any room in your home, our hanging chairs provide a cozy and relaxing seating option that will add a touch of natural beauty to your decor. They are great for indoor or outdoor use and can be easily hung from a sturdy beam or ceiling hook.",
      category: "Hangingchairs",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Thonet Bamboo Rocking Chair",
      price:48,
      lastPrice :60,
      image:
      rockingChair1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 78,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " These rocking chairs are often used in living rooms, patios, or outdoor spaces for relaxation and comfort. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Recliner Rocking Chair",
      price:45,
      lastPrice :55,
      image:
      rockingChair2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 59,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  The unique design of this rocking chair provides a relaxing and calming experience, reducing stress and promoting mental health.They  are great for outdoor living spaces such as patios, decks, or gardens. ",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rustic Rocking Lounge Chair",
      price:50,
      lastPrice :60,
      image:
      rockingChair3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 96,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " The versatile design of these chairs can fit well in any home decor, adding a touch of natural beauty to any space. They are great for outdoor living spaces such as patios, decks, or gardens.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Relaxer Cane Rocking Chair",
      price:44,
      lastPrice :55,
      image:
      rockingChair4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 72,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  These chairs are typically designed with a curved base, allowing for a smooth rocking motion and optimal comfort. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Relaxing Bamboo Rocking Chair",
      price:43,
      lastPrice :52,
      image:
      rockingChair5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 64,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " If you are looking for simple relciner relaxing chair add this stunning piece of furniture to your home today and experience the comfort and beauty of nature in your own space.They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Garden Patio Cane Rocking Chair",
      price:49,
      lastPrice :60,
      image:
      rockingChair6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 84,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " These Bamboo cane rocking chairs are great for outdoor living spaces such as patios, decks, or gardens. They are lightweight and durable, making them easy to move around and resistant to wear and tear.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Bamboo Children Rocking Chair",
      price:50,
      lastPrice :60,
      image:
      rockingChair7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 81,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " Children's rocking chairs are specially designed and sized for children to comfortably use for play, relaxation, or reading. They come in various shapes, sizes, and materials, but typically feature a sturdy and safe design that can withstand the wear and tear of young children.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Ergonomic Cane Children Rocking Chair",
      price:49,
      lastPrice :58,
      image:
      rockingChair8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 95,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  Our Children's cane rocking chairs are great for outdoor living spaces such as patios, decks, or gardens. It encourages imaginative play and creativity. It is lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Indoor/ Outdoor Bamboo Rocking Chair",
      price:54,
      lastPrice :62,
      image:
      rockingChair9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 79,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " These rocking chairs are designed to create a soothing and relaxing motion while seated. They typically feature a curved base that allows the chair to rock back and forth, creating a gentle swaying motion that can help reduce stress and promote relaxation.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Antique Natural Rattan Rocking Chair",
      price:55,
      lastPrice :65,
      image:
      rockingChair10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 71,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  These antique style rocking chairs are great for outdoor living spaces such as patios, decks, or gardens.They can be used in a variety of settings, including living rooms, bedrooms, and outdoor spaces like porches and patios. Whether used for relaxation or simply as a comfortable seating option, a rocking chair can be a great addition to any home. ",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Classic Rattan Cane Rocking Chair",
      price:43,
      lastPrice :52,
      image:
      rockingChair11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 69,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  Our rattan rocking chair boasts unique aesthetics, with a design that is both modern and timeless. It fits perfectly in any style of home decor, making it a versatile and practical addition to any space. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Cane Kids Rocking Chair",
      price:54,
      lastPrice :64,
      image:
      rockingChair12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 59,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  It provides a comfortable seating option for reading or watching TV. They are great for outdoor living spaces such as patios, decks, or gardens. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Retro Bamboo Cane Rocking Chair",
      price:58,
      lastPrice :67,
      image:
      rockingChair13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 94,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade. They are great for outdoor living spaces such as patios, decks, or gardens. The quality of our rocking chair ensures it will last for years to come, becoming a cherished piece of furniture for generations to come. ",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Mid-Century Bamboo Rocking Chair",
      price:53,
      lastPrice :62,
      image:
      rockingChair14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 109,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        "  These rocking chairs are stylish and eco-friendly option for indoor and outdoor seating, offering durability, comfort, and unique aesthetic appeal while supporting proper posture and alignment. They are great for outdoor living spaces such as patios, decks, or gardens. They are lightweight and easy to move, making them perfect for enjoying the sunshine or relaxing in the shade.",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Swivel Rocking Chair",
      price:41,
      lastPrice :50,
      image:
      rockingChair15,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 58,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Cane",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Courtyard, Lounge, Hotel, Restaurant",
      description:
        " This chair is lightweight and easy to move around, making them ideal for both indoor and outdoor use.They are ideal for indoor or outdoor use, including in living rooms, dining rooms, patios, and gardens. ",
      category: "Rockingchair",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handcrafted Wicker Storage Box",
      price: 24,
      lastPrice :30,
      image:
      storageBox8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 43,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " It offers a stylish and practical solution for organizing your home or office. These have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Rattan Woven Storage Trunk",
      price: 32,
      lastPrice :42,
      image:
      storageBox9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 39,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " If you are looking solution for storing clothing, toys, or office supplies, our versatile and sturdy boxes are up to the task.They have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. They can also be used for a variety of purposes, including as a coffee table, storage ottoman, or as a decorative accent in any room of the house. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Multipurpose Designer Wicker Storage Organizer",
      price: 24,
      lastPrice :32,
      image:
      storageBox10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 48,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These are lightweight and easy to move around, making them a convenient storage option.These storage boxes can be used for storing a variety of items such as clothing, toys, books, magazines, office supplies, and more. They are suitable for use in homes, offices, and other living spaces. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handwoven Round Rattan Laundry Storage Box",
      price: 22,
      lastPrice :30,
      image:
      storageBox11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 65,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These storage boxes have a natural and stylish look that can complement any interior décor. They can be used for a variety of purposes, including as a storage ottoman, or as a decorative accent in any room of the house. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Wicker Storage Chest",
      price: 35,
      lastPrice :45,
      image:
      storageBox12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 62,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These large boxes offer many benefits, including eco-friendliness, durability, versatility, affordability, and a natural and stylish aesthetic. You can keep your belongings organized, free up space, and contribute to a more sustainable and eco-conscious lifestyle.",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Designer Rattan Cane Box",
      lastPrice :40,
      image:
      storageBox13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 78,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Cane ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These storage boxes have a natural and stylish look that can complement any interior décor. They are lightweight and easy to move around, making them a convenient storage option. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bamboo Rattan Hinged Storage Box",
      price: 35,
      lastPrice :45,
      image:
      storageBox14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 29,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage -Garden, Bedroom, Office, Hotel, Restaurant",
      description:
        " These bamboo rattan large storage boxes have a spacious interior that can hold a variety of items, including blankets, toys, clothes, and more. It can be used for a variety of purposes, including as a coffee table, storage ottoman, or as a decorative accent in any room of the house. ",
      category: "storagebox",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Rattan Pet Bed",
      price: 25,
      lastPrice :35,
      image:
      petBed20,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 42,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Rattan, Sarkanda ",
      feature4:"100% Recycle",
      feature5:"Customized Packaging",
      feature6:"Usage - Dogs, Kitty, Cats",
      description:
        "  This type of pet bed can be used indoors or outdoors, making it a versatile choice for pet owners who want a bed that can be moved around as needed.",
    category: "PetBeds",
      amount: 1,
      stock: 4,
    },   
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Rattan Retreat Patio Ottoman Pair",
      price: 34,
      lastPrice :42,
      image: mudhaSet1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 107,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The natural and rustic look of bamboo and rattan adds warmth and texture to any space, making a pair of ottoman set of different sizes great decorative accents.  Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Rustic Rattan Stool Set",
      price: 33,
      lastPrice :42,
      image:
      mudhaSet2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 72,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This pair of different sizes of rattan ottoman sets can be a practical and stylish addition to any living space.Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Bambmoo Bistro Handmade Wicker Ottoman Set",
      price: 36,
      lastPrice :48,
      image:
      mudhaSet3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 98,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This handmade beautiful set is useful in number of ways . You can place the smaller ottoman in front of the larger one, or you can place them side by side to create a unique and stylish seating arrangement.Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Natural Bamboo Bliss Ottoman Set",
      price: 35,
      lastPrice :48,
      image:
      mudhaSet4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 112,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This pair of bamboo ottomans of different sizes can be used to create a layered and visually interesting look in your living room or other space. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan , which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Rustic Cane Decorative Ottoman Set",
      price: 38,
      lastPrice :50,
      image:
      mudhaSet5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 168,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan,Cane",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "Having two ottomans of different sizes allows for greater versatility in your seating arrangements. Our decorative cane ottomans are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quaelity cane, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Multicolor Rattan Bamboo Mudha Set",
      price: 98,
      lastPrice :120,
      image:
      mudhaSet6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 189,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " Our Bamboo Mudhas are the perfect blend of style, sustainability, and craftsmanship. These mudhas are crafted using only the finest quality bamboo, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Double Delight Multi Colour Rattan Bamboo Ottoman Set",
      price: 57,
      lastPrice :75,
      image:
      mudhaSet7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 96,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This set of ottoman is eco-friendly and sustainable. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These Ottomans are crafted using only the finest quality bamboo rattan. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Perfect Pair Garden Bamboo Rattan Stool",
      price: 34,
      lastPrice :45,
      image:
      mudhaSet8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 124,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " They are also practical and functional, providing additional seating or footrests when needed.Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Set of 2 Handmade Bamboo Rattan Sticks Stool",
      price: 34,
      lastPrice :45,
      image:
      mudhaSet9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 114,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "These are lightweight, easy to move, and can be used indoors or outdoors.Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Green Rattan Ottoman With Mini Size Wicker Foot Stool",
      price: 35,
      lastPrice :45,
      image:
      mudhaSetl0,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 108,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "These bamboo rattan ottoman sets are also highly flexible, allowing for intricate weaving and unique designs. Additionally, they have a natural and rustic aesthetic that adds warmth and character to any space.",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Red Rattan Wicker Ottoman Set",
      price: 34,
      lastPrice :42,
      image:
      mudhaSetl1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " These sets of rattan bamboo stools are the perfect blend of style, sustainability, and craftsmanship. This pair of ottoman set of different sizes is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Handmade Round Bamboo Bar Stool Pair",
      price: 37,
      lastPrice :46,
      image:
      mudhaSetl2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 114,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " The natural and rustic look of bamboo and rattan adds warmth and texture to any space, making  a pair of ottoman set of different sizes great decorative accents. They are also practical and functional, providing additional seating or footrests when needed. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Eco-freindly Bamboo Mudha Set",
      price: 36,
      lastPrice :45,
      image:
      mudhaSetl3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 140,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " A pair of ottoman set of different sizes can be used as versatile and functional furniture pieces in various settings such as living rooms, bedrooms, patios, and even offices. They can be used as additional seating, footrests, or decorative accents. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Stylish Handcrafted Garden Rattan Stool Set ",
      price: 34,
      lastPrice :45,
      image:
      mudhaSetl4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 119,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This set of stool of different size is lightweight, easy to move, and can be used indoors or outdoors.Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Wicker Rattan Ottoman Set",
      price: 34,
      lastPrice :45,
      image:  mudhaSetl5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This pair of ottoman set of different sizes is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Ottoman",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Classic Multicolor Bamboo Outdoor Small Kahtiya",
      price: 52,
      lastPrice :65,
      image:
      bench1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 67,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This small bamboo bench can be used as a seating option in small spaces such as balconies, patios, and gardens.It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our bamboo rattan benches are the perfect blend of style, sustainability, and craftsmanship. These benches are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Patio Rattan Small Bench",
      price: 51,
      lastPrice :65,
      image:
      bench2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 56,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This small bamboo bench is suitable for both indoor and outdoor use, making it a versatile and practical option.It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our bamboo rattan benches are the perfect blend of style, sustainability, and craftsmanship. These benches are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Beige Natural Bamboo Low Height Bench",
      price: 50,
      lastPrice :60,
      image:
      bench3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 88,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This bamboo bench has a unique, natural texture that can add character and charm to any space. It is lightweight and easy to move around, making it a versatile piece of furniture. These small benches are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handwoven Charpai Bamboo Rattan Cot",
      price: 112,
      lastPrice :150,
      image:
      charpai1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 78,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The woven design of a bamboo charpai/cot provides a firm yet comfortable surface for sleeping or lounging. It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our bamboo cots are the perfect blend of style, sustainability, and craftsmanship. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Traditional Webbing Bamboo Rattan Kahtiya",
      price: 110,
      lastPrice :155,
      image:
      charpai2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 49,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "Handcrafted bamboo charpais/ khatiya can be used both indoors and outdoors, making them a versatile piece of furniture.It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Bamboo Weaving Classic Charpai Bed",
      price: 106,
      lastPrice :145,
      image:
      charpai3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 73,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This bamboo charpai has a rustic and traditional aesthetic that can add charm and character to any space. It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our bamboo rattan traditional beds are the perfect blend of style, sustainability, and craftsmanship. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Fabric Woven Traditional Bamboo Bed",
      price: 124,
      lastPrice :54,
      image:
      charpai4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "Sleeping on a firm surface of fabric woven cot like a bamboo charpai can provide orthopedic benefits and help alleviate back pain.It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our Bamboo charpais/ cots are the perfect blend of style, sustainability, and craftsmanship. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Vintage Antique Kahtiya Bamboo Charpoy",
      price: 114,
      lastPrice :150,
      image:
      charpai5,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 92,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This handcrafted bamboo charpai is a beautiful, versatile, and eco-friendly piece of furniture that provides comfort, durability, and affordability. It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our Bamboo charpais are the perfect blend of style, sustainability, and craftsmanship.  ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Moonj Grass Bamboo Indian Cot",
      price: 104,
      lastPrice :145,
      image:
      charpai6,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 89,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This charpai cot bed is a versatile piece of furniture that can be used both indoors and outdoors. Whether you need an extra bed for guests or a relaxing spot to read a book, the charpai cot bed can accommodate your needs. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Multicolor Thread Handwoven Bamboo Large Bench",
      price: 119,
      lastPrice :148,
      image:
      charpai7,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The woven design of this charpai cot provides a firm yet comfortable sleeping surface that promotes good posture and can help alleviate back pain. Whether you need an extra bed for guests or a relaxing spot to read a book, the charpai cot bed can accommodate your needs. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Rattan Woven Bamboo Classic Bench",
      price: 118,
      lastPrice :150,
      image:
      charpai8,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 59,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This charpai handwoven bench is a stylish, sustainable, and versatile piece of furniture that can be used to add a modern twist to traditional designs. Whether you are looking to furnish your home or add a unique touch to your outdoor space, the charpai cot bed is an excellent choice for those who want to add a touch of style and sophistication to their space. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Vintage Handcrafted Bamboo Bed With Set Of Peedi",
      price: 160,
      lastPrice :210,
      image:
      charpai9,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 84,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The charpai bed set is a unique and eye-catching piece of furniture that is sure to impress your guests and add a touch of character to your space. It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Mid Century Bamboo Rattan Charpoy",
      price: 102,
      lastPrice :140,
      image:
      charpai10,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 72,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "If you are looking to furnish your home or add a unique touch to your outdoor space, this charpai is an excellent choice for those who want to add a touch of style and sophistication to their space.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Decorative Patio Traditional Bamboo Bed",
      price: 110,
      lastPrice :105,
      image:
      charpai11,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 133,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " These decrative bmaboo beds  have a traditional aesthetic, modern designs with sleek finishes and clean lines can add a contemporary twist to your space. It is not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Wicker Rattan Bamboo Charpai",
      price: 109,
      lastPrice :140,
      image:
      charpai12,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 55,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "This handcrafted bamboo design of the charpai cot is not only beautiful but also sleek and elegant, providing a stylish and refined look. It is lightweight and easy to move around ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: "Classic Handwoven Bamboo Indian Bed",
      price: 134,
      lastPrice :150,
      image:
      charpai13,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 61,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This handwoven bed is a durable, comfortable, and eco-friendly piece of furniture that can add character and charm to any living space. Its affordability, versatility, and easy maintenance make it an ideal choice for those who want a sustainable and long-lasting piece of furniture.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Set Of 3 Handwoven Traditional Bamboo Indian Beds",
      price: 174,
      lastPrice :215,
      image:
      charpai14,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 68,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The woven designs of a bamboo bed cot provides a firm yet comfortable surface for sleeping or relaxing. The design allows for proper ventilation, keeping the surface cool and comfortable.These cots are easy to clean and maintain, requiring only occasional wiping with a damp cloth. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Rectangular Handcrafted Bamboo Stool",
      price: 27,
      lastPrice :35,
      image:
      peedi1,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 83,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        " This small bamboo rattan low height stools are a versatile, affordable, and eco-friendly seating option that can add a touch of traditional charm and character to any living space.These are lightweight and easy to move ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Natural Bamboo Woven Peedi",
      price: 30,
      lastPrice :40,
      image:
      peedi2,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 103,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "These bamboo rattan peedi low height stools are primarily used as low seating options for traditional settings like living rooms, patios, or gardens. They can also be used as footrests or decorative items in a room.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Modern Stylish Bamboo Stool Peedi Set",
      price: 55,
      lastPrice :65,
      image:
      peedi3,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 87,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "The natural and traditional design of these peedi/ stools adds character and charm to any living space. These are not only a great way to add functional and eco-friendly furniture to your space, but also a way to support local artisans and promote sustainable practices. Our Bamboo stools are the perfect blend of style, sustainability, and craftsmanship. These stools are crafted using only the finest quality bamboo rattan, which is fully eco-friendly and recyclable. ",
      category: "Bench",
      amount: 1,
      stock: 4,
    },
    {
      id: "recoW8ecgjtKx2Sj2",
      name: " Handmade Patio Bamboo Bench Peedi",
      price: 34,
      lastPrice :45,
      image:
      peedi4,
      colors: ["#ff0000", "#ffb900", "#00ff00"],
      company: "Ecowoodies",
      reviews: 93,
      featured: false,
      feature1:"Eco-friendly",
      feature2:"Customized",
      feature3:"Material - Bamboo, Rattan, Wicker",
      feature4:"100% Recycle",
      feature5:"Footrest, Seating, Storage, Decorative, Patio",
      feature6:"Usage -Home, Garden, Courtyard, Farmhouse, Beach,  Bar, Pubs, Hotel, Restaurant",
      description:
        "These peedis/ low height benches can be used both indoors and outdoors, making them a versatile seating option.The woven rattan seating surface provides a comfortable and supportive seating option.",
      category: "Bench",
      amount: 1,
      stock: 4,
    },



  ];
  export default data;