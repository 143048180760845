export class ProductData {
    id!: string;
    name!: string;
    image!: string;
    price!: number | undefined;
    category!: string;
    description!: string;
    reviews!: number | undefined;
    lastPrice!: number | undefined;
    feature1!: string;
    feature2!: string;
    feature3!: string;
    feature4!: string;
    feature5!: string;
    feature6!: string;
}