import kidsbed from "./images/trending/DogPet.jpeg"
import storagebox from "./images/trending/storage.png"
import rattanChair from "./images/trending/rattanChair.png"
import chairSet from "./images/trending/PapasanChair.jpg"
import wallDecor from "./images/trending/wallDecor.jpg"
import kidsSofa from "./images/trending/kidsSofa.png"



const trendings = [

   

    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      kidsbed,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "PetBeds",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      storagebox,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "storagebox",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },   
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      rattanChair,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "Rockingchair",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },   

    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      chairSet,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "Rockingchair",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },   
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      wallDecor,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "baskets",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },
    
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      kidsSofa,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "NewBorns",
      shipping: false,
      amount: 1,
      stock: 4,
      
    },   



  ];
  export default trendings;