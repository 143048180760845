import bamboCategory from "./images/category/LOUNGE.jpg"
import stoolCategory from "./images/category/stools.jpg"   
import newBornBasket from "./images/category/newBornbasket.jpg"
import newBornBasketFeatured from "./images/category/newbornFeatured.jpg"

import hangingLight from "./images/category/lighting.jpg"
import petCategory from "./images/category/petCategory.jpg" 
import bamboosofaCategory from "./images/category/bambooSofas.jpg" 
import BamboodiningsetsCategory from "./images/category/Bamboodiningsets.jpg" 
import basketsCategory from "./images/category/baskets.jpg" 
import basketsCategoryFeatured from "./images/category/basketsFeatured.jpg" 
import BamboodollstrollersCategory from "./images/category/Babydollstrollers.jpg" 
import BamboodollstrollersFeatured from "./images/category/BabydollstrollersFeatured.jpg" 
import HangingchairsCategory from "./images/category/Hangingchairs.jpg" 
import KidchairsCategory from "./images/category/Kidchairs.jpg"
import RockingchairCategory from "./images/category/rockingChair.jpg"
import SofasetCategory from "./images/category/SofasetCategory.jpg"
import traysCategory from "./images/category/trays.jpg"
import storageboxCategory from "./images/category/storagebox.jpg"
import BambooamplifierCategory from "./images/category/Bambooamplifier.jpg"
import Chairs2Category from "./images/category/Chairs2.jpg"
import ShelvesCategory from "./images/category/Shelves.jpg"
import TablesCategory from "./images/category/Tables.jpg"
import Bench from "./images/category/Bench.jpg"


const categories = [

   
    // {
    //   id: "rec5NBwZ5zCD9nfF0",
    //   name: "Bamboo Sofa Set",
    //   price: 24,
    //   image:
    //   sofaCategory,
    //   colors: ["#ffb900", "#0000ff"],
    //   company: "Ecowoodies",
    //   featured: false,
    //   description:
    //     "Cloud bread VHS hell of banjo bicycle rights jianbing umami mumblecore etsy 8-bit pok pok +1 wolf. Vexillologist yr dreamcatcher waistcoat, authentic chillwave trust fund. Viral typewriter fingerstache pinterest pork belly narwhal. Schlitz venmo everyday carry kitsch pitchfork chillwave iPhone taiyaki trust fund hashtag kinfolk microdosing gochujang live-edge",
    //   category: "Sofaset",
    //   amount: 1,
    //   stock: 4,
    // },
    {
      id: "recoM2MyHJGHLVi5l",
      name: "Handcrafted Bamboo, Rattan, and Sarkanda Ottomans Pouf & Stools | Customizable, Affordable, and Versatile Furniture",
      price: 20,
      image:
      stoolCategory,
      colors: ["#000"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our wide range of handcrafted ottomans and stools made with eco-friendly bamboo, rattan, and sarkanda materials. Discover the perfect addition to your space, whether it's a cafe, restaurant, hotel, bar, or patio. Our products are customizable to suit your preferences in terms of size and color. With iron frames for added durability, our ottomans and stools are not only stylish but also affordable. Browse our collection now and find the ideal piece that combines craftsmanship, sustainability, and exceptional value.",
      category: "Ottoman",
      shipping: true,
      amount: 1,
      stock: 4,
      
    },
    {
      id: "recd1jIVIEChmiwhe",
      name: "Elevate Your Space with Handcrafted Bamboo, Rattan, and Sarkanda Lounge Chairs | Customizable and Affordable Furniture for Cafes, Restaurants, Hotels, and More",
      price: 38,
      image: bamboCategory,
      colors: ["#000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover our exclusive collection of handcrafted lounge chairs made from sustainable bamboo, rattan, and sarkanda. Experience the perfect blend of style, comfort, and affordability with our customizable chairs, available in various sizes and colors. Crafted with iron frames for durability, our lounge chairs are ideal for enhancing the ambiance of cafes, restaurants, hotels, bars, and even outdoor patios. Explore our wide range of designs and transform your space into a stylish haven. Shop now and create a welcoming atmosphere with our top-ranked lounge chairs in the USA search engine.",
      category: "BambooLoungeChair",
      shipping: true,
      amount: 1,
      stock: 4,
      
    },
   
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Discover Stylish and Sustainable Dining Sets | Handcrafted Bamboo, Rattan, and Sarkanda Furniture",
      price: 25,
      image:
      BamboodiningsetsCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Elevate your dining experience with our handcrafted dining sets made from eco-friendly bamboo, rattan, and sarkanda. Our customizable sets offer a perfect blend of style and functionality, catering to the latest dining trends. Create a captivating dining space with our versatile range, available in various sizes and colors. Crafted with durability in mind, our iron-framed dining sets are perfect for restaurants, cafes, hotels, bars, and even outdoor patios. Explore our trending collection of sustainable dining sets and transform your space with top-quality, affordable furniture.",
      category: "Bamboodiningsets",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Discover Handcrafted Rattan Baskets | Customizable, Eco-Friendly, and Versatile",
      price: 25,
      image:
      basketsCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our exquisite collection of handcrafted rattan baskets made from sustainable materials such as bamboo, rattan, and sarkanda. These versatile baskets can be customized to suit any size and color preference. Crafted with iron frames for durability, our affordable rattan baskets are perfect for cafes, restaurants, hotels, bars, and as patio furniture. Add a touch of natural elegance to your space with our eco-friendly and customizable rattan baskets. Browse our wide selection and find the perfect storage solution that combines style and functionality for your needs.",
      category: "baskets",
      shipping: true,
      amount: 1,
      stock: 4,
    },	
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Discover Handcrafted Rattan Baskets | Customizable, Eco-Friendly, and Versatile",
      price: 25,
      image:
      basketsCategoryFeatured,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our exquisite collection of handcrafted rattan baskets made from sustainable materials such as bamboo, rattan, and sarkanda. These versatile baskets can be customized to suit any size and color preference. Crafted with iron frames for durability, our affordable rattan baskets are perfect for cafes, restaurants, hotels, bars, and as patio furniture. Add a touch of natural elegance to your space with our eco-friendly and customizable rattan baskets. Browse our wide selection and find the perfect storage solution that combines style and functionality for your needs.",
      category: "baskets",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Adorable Handcrafted Baby Doll Strollers | Customizable, Eco-Friendly, and Safe",
      price: 25,
      image:
      BamboodollstrollersCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover our charming collection of handcrafted baby doll strollers made with love from sustainable materials including bamboo, rattan, and sarkanda. These adorable strollers can be customized to your desired size and color, ensuring a unique and personalized touch. Designed with iron frames for durability and safety, our affordable baby doll strollers are perfect for newborn photography shoots, props, and at-home play. Let your little ones' imagination soar as they take their beloved dolls for a stroll in style. Explore our wide selection and create cherished memories with our eco-friendly and customizable baby doll strollers.",
      category: "Babydollstrollers",
      shipping: true,
      amount: 1,
      stock: 4,
    },

    {
      id: "recZkNf2kwmdBcqd0",
      name: "Adorable Handcrafted Baby Doll Strollers | Customizable, Eco-Friendly, and Safe",
      price: 25,
      image:
      BamboodollstrollersFeatured,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Discover our charming collection of handcrafted baby doll strollers made with love from sustainable materials including bamboo, rattan, and sarkanda. These adorable strollers can be customized to your desired size and color, ensuring a unique and personalized touch. Designed with iron frames for durability and safety, our affordable baby doll strollers are perfect for newborn photography shoots, props, and at-home play. Let your little ones' imagination soar as they take their beloved dolls for a stroll in style. Explore our wide selection and create cherished memories with our eco-friendly and customizable baby doll strollers.",
      category: "Babydollstrollers",
      shipping: true,
      amount: 1,
      stock: 4,
    },

    
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Relax in Style with Handcrafted Hanging Hammock Swing Chairs | Customizable and Eco-Friendly",
      price: 25,
      image:
      HangingchairsCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Transform your space into a cozy oasis with our handcrafted hanging hammock swing chairs. Made with care using bamboo, rattan, and sarkanda, these swing chairs offer both comfort and style. Customize your swing chair to your desired size and color, and experience the ultimate relaxation in your favorite spot. Crafted with sturdy iron frames, our swing chairs are not only affordable but also durable. Perfect for cafes, restaurants, hotels, bars, and even as patio furniture, these swing chairs add a touch of charm to any setting. Explore our collection and elevate your lounging experience with our eco-friendly and customizable hanging hammock swing chairs.",
      category: "Hangingchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Adorable and Customizable Handcrafted Kids Chairs | Bamboo, Rattan, and Sarkanda Creations",
      price: 25,
      image:
      KidchairsCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover our charming collection of handcrafted kids chairs, made with love using bamboo, rattan, and sarkanda. These chairs can be customized to suit any size and color preferences, ensuring the perfect fit for your little ones. Crafted with durable iron frames, our kids chairs are not only affordable but also built to last. Whether for cafes, restaurants, hotels, or as delightful additions to your home, these chairs bring comfort and style to any setting. They are also ideal for newborn photoshoots, providing an adorable prop, and can even be used as patio furniture for your little one's outdoor adventures. Explore our range and let your child's imagination soar with our eco-friendly and customizable kids chairs. ",
      category: "Kidchairs",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Timeless Handcrafted Rocking Chairs | Bamboo, Rattan, and Sarkanda Creations",
      price: 25,
      image:
      RockingchairCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        " Indulge in the soothing rhythm of our handcrafted rocking chairs, meticulously created with bamboo, rattan, and sarkanda. Each chair can be customized to your desired size and color, ensuring a perfect match for your style. Crafted with sturdy iron frames, our rocking chairs offer both durability and affordability, making them an ideal choice for cafes, restaurants, hotels, and homes alike. Embrace the versatility of these chairs as they add comfort and charm to any space. Perfect for newborn photoshoots or as patio furniture, our rocking chairs invite relaxation and provide a touch of elegance to every setting. Explore our collection and experience the timeless appeal of our eco-friendly and customizable rocking chairs.",
      category: "Rockingchair",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Elevate Your Space with Handcrafted Sofa Sets | Bamboo, Rattan, and Sarkanda Creations",
      price: 25,
      image:
      SofasetCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover the artistry and comfort of our handcrafted sofa sets, skillfully crafted with bamboo, rattan, and sarkanda. Each piece can be tailored to your specifications, offering endless possibilities in size and color. Designed with sturdy iron frames, our sofa sets combine durability with affordability, making them a popular choice for cafes, restaurants, hotels, bars, and even outdoor patios. Immerse yourself in the beauty and functionality of our eco-friendly and customizable creations, as they transform any space into a haven of relaxation and style. Explore our exquisite collection and experience the allure of handmade furniture at its finest.",
      category: "Sofaset",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Elevate Your Serving Experience with Handcrafted Tray/Platter Collection | Bamboo, Rattan, and Sarkanda Creations",
      price: 25,
      image:
      traysCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover the beauty and versatility of our handcrafted serving trays and platters, meticulously crafted with bamboo, rattan, and sarkanda. Customizable in size and color, each piece is designed to enhance your serving experience. With the added durability of iron frames, our trays/platters are a cost-effective choice for cafes, restaurants, hotels, bars, and homes. Experience the blend of functionality and aesthetics as you showcase your culinary delights with our eco-friendly creations. Browse our exquisite collection and add a touch of elegance to your dining experience.",
      category: "trays",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Organize in Style with Handcrafted Bamboo, Rattan, and Sarkanda Storage Boxes | Customizable and Affordable",
      price: 25,
      image:
      storageboxCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our collection of beautifully handcrafted storage boxes made from bamboo, rattan, and sarkanda. These versatile boxes can be customized to suit any size and color preference, providing an elegant and eco-friendly storage solution. With the added durability of iron frames, our storage boxes are perfect for cafes, restaurants, hotels, bars, and homes. Experience the perfect blend of functionality and aesthetics as you declutter and enhance your space. Browse our affordable and customizable options to find the ideal storage solution that suits your style and needs.",
      category: "storagebox",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Enhance Your Audio Experience with Handcrafted Bamboo, Rattan, and Sarkanda Sound Amplifiers | Customizable and Affordable",
      price: 25,
      image:
      BambooamplifierCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Immerse yourself in rich, high-quality sound with our handcrafted sound amplifiers made from bamboo, rattan, and sarkanda. These exquisite pieces can be customized to your preferred size and color, ensuring a seamless blend with your decor. Made with durable iron frames, our sound amplifiers are not only aesthetically pleasing but also budget-friendly. Whether you're in a cafe, restaurant, hotel, bar, or at home, our amplifiers will elevate your audio experience. Explore our collection today and discover the perfect combination of craftsmanship, versatility, and affordability.",
      category: "Bambooamplifier",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Experience Comfort and Sustainability with Handcrafted Organic Chairs | Bamboo, Rattan, and Sarkanda",
      price: 25,
      image:
      Chairs2Category,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover the perfect blend of comfort and sustainability with our handcrafted organic chairs made from bamboo, rattan, and sarkanda. Each chair is meticulously crafted and can be customized to your desired size and color, ensuring a seamless fit for any space. With durable iron frames, our chairs offer both style and affordability, making them ideal for cafes, restaurants, hotels, bars, and homes. Embrace eco-friendly living without compromising on aesthetics. Explore our collection of organic chairs today and transform your space with timeless elegance and conscious craftsmanship.",
      category: "Chairs2",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Maximize Space and Style with Handcrafted Shelves and Racks | Bamboo, Rattan, and Sarkanda",
      price: 25,
      image:
      ShelvesCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our collection of handcrafted shelves and racks made from bamboo, rattan, and sarkanda. Each piece is thoughtfully designed and can be customized to fit any size and color preference. Our shelves and racks are not only functional but also add a touch of elegance to any space. Made with durable iron frames, they offer both affordability and durability, making them perfect for cafes, restaurants, hotels, bars, and homes. Maximize your space with our versatile and eco-friendly storage solutions. Discover the perfect shelf or rack for your needs and elevate your interior design.",
      category: "Shelves",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Elevate Your Space with Handcrafted Tables and Ottoman Sets | Bamboo, Rattan, and Sarkanda",
      price: 25,
      image:
      TablesCategory,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        " Discover our exquisite collection of handcrafted tables and ottoman sets, meticulously made using bamboo, rattan, and sarkanda. Each piece is customizable to your preferred size and color, ensuring a perfect fit for your space. With durable iron frames, our tables and ottoman sets offer both affordability and quality craftsmanship. Widely used in cafes, restaurants, hotels, bars, and homes, these versatile pieces add style and functionality to any setting. Experience the beauty of eco-friendly furniture and elevate your interior with our exceptional collection of tables and ottoman sets.",
      category: "Tables",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Capture Precious Moments with Handcrafted Newborn Photography Props | Bamboo, Rattan, and Sarkanda",
      price: 25,
      image:
      newBornBasket,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our collection of handcrafted newborn photography props, crafted with care using bamboo, rattan, and sarkanda. Each prop can be customized to suit any size or color, ensuring a perfect fit for your photography needs. With sturdy iron frames, our props offer durability and affordability, making them an ideal choice for photographers. Widely used for newborn photography sessions, these props create a charming and cozy ambiance, enhancing the beauty of every captured moment. Discover our range of eco-friendly and versatile newborn photography props, designed to add a touch of elegance to your photography sessions.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Capture Precious Moments with Handcrafted Newborn Photography Props | Bamboo and Rattan",
      price: 25,
      image:
      newBornBasketFeatured,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: true,
      description:
        "Explore our collection of handcrafted newborn photography props, crafted with care using bamboo, rattan, and sarkanda. Each prop can be customized to suit any size or color, ensuring a perfect fit for your photography needs. With sturdy iron frames, our props offer durability and affordability, making them an ideal choice for photographers. Widely used for newborn photography sessions, these props create a charming and cozy ambiance, enhancing the beauty of every captured moment. Discover our range of eco-friendly and versatile newborn photography props, designed to add a touch of elegance to your photography sessions.",
      category: "NewBorns",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recEHmzvupvT8ZONH",
      name: "Give Your Beloved Pets the Ultimate Comfort with Handcrafted Pet Beds | Bamboo, Rattan, and Sarkanda",
      price: 17,
      image:
      petCategory,
      colors: ["#000", "#ffb900"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Explore our collection of handcrafted pet beds, made with love and care using bamboo, rattan, and sarkanda. These beds are fully customizable, allowing you to choose the perfect size and color for your furry friends. Crafted with sturdy iron frames, our pet beds offer durability and affordability, making them an excellent choice for pet owners. Give your pets a cozy and stylish place to sleep, as our beds are widely used for their comfort and functionality. Discover our range of eco-friendly pet beds, designed to provide your pets with the ultimate comfort they deserve.",
      category: "PetBeds",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Illuminate Your Space with Handcrafted Lamps and Lighting | Bamboo, Rattan, and Sarkanda",
      price: 15,
      image:
      hangingLight,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Discover our exquisite collection of handcrafted lamps and lighting, expertly crafted using bamboo, rattan, and sarkanda. These unique pieces can be customized to any size and color, allowing you to create the perfect ambiance for your space. Made with durable iron frames, our lamps and lighting fixtures offer both style and affordability, making them a popular choice for cafes, restaurants, hotels, bars, and homes. Add a touch of elegance and warmth to your surroundings with our eco-friendly and budget-friendly lighting options. Explore our selection and find the perfect lighting solution to illuminate your space with beauty and charm.",
      category: "Lighting",
      shipping: true,
      amount: 1,
      stock: 4,
    },
    {
      id: "recotY5Nh00DQFdkm",
      name: "Create Your Outdoor Oasis with Handcrafted Bamboo Patio Furniture | Rattan and Sarkanda Options Available",
      price: 15,
      image:
      Bench,
      colors: ["#00ff00", "#0000ff", "#ff0000"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Transform your outdoor space into a serene retreat with our Bamboo Patio Collection. Explore our handcrafted furniture, including chairs, tables, loungers, and more, made using bamboo, rattan, and sarkanda. These pieces are customizable to fit any size and color preference, and feature durable iron frames for long-lasting quality. Whether you own a cafe, restaurant, hotel, bar, or simply want to enhance your home patio, our affordable and eco-friendly furniture will add style and comfort to your outdoor oasis. Discover the perfect pieces to elevate your outdoor living experience with our Bamboo Patio Collection.",
      category: "Bench",
      shipping: true,
      amount: 1,
      stock: 4,
    }



  ];
  export default categories;