import bamboCategory from "./images/category/LOUNGE.jpg"
import stoolCategory from "./images/category/stools.jpg"
//import sofaCategory from "./images//category/sofaset.jpg"
import newBornBasket from "./images/category/newBornbasket.jpg"
import hangingLight from "./images/category/lighting.jpg"
import petCategory from "./images/category/petCategory.jpg" 
import bamboosofaCategory from "./images/category/bambooSofas.jpg" 

const blogs = [
    {
      id: "recZkNf2kwmdBcqd0",
      name: "Designs can have a way of life ",
      price: 25,
      image:
      newBornBasket,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Ecowoodies, Designs are you can have a way of life that is both solid and aware of the earth. They offer everything from delicate and sleek cushions and sheets, to normal fiber floor coverings and tangles flawlessly highlighting your lounge. Ecowoodies restrain their synthetic utilize, reasonably source their materials, and go natural when conceivable, guaranteeing that their items are earth-accommodating and alright for your family. Intended for a solid planet and sound home, Ecowoodies responsibility regarding maintainability and eco-accommodating furniture is an essential piece of their plan of action. Ecowoodies dependable assembling process happens in INDIA, eliminating carbon emanations, and they don't utilize fire retardants or brutal chemicals.Ecowoodies furniture has been highlighted by different pioneers, they offer the alternative to modify their cutting edge and smooth items to your preferring - it's a win-win with any piece from Ecowoodies." ,
      category: "NewBorns", 
     
      url:''
    },
    {
      id: "recEHmzvupvT8ZONH",
      name: "Pet Beds",
      price: 17,
      image:
      petCategory,
      colors: ["#000", "#ffb900"],
      company: "Ecowoodies",
      featured: false,
      description:
        "Cloud bread VHS hell of banjo bicycle rights jianbing umami mumblecore etsy 8-bit pok pok +1 wolf. Vexillologist yr dreamcatcher waistcoat, authentic chillwave trust fund. Viral typewriter fingerstache pinterest pork belly narwhal. Schlitz venmo everyday carry kitsch pitchfork chillwave iPhone taiyaki trust fund hashtag kinfolk microdosing gochujang live-edge",
      category: "PetBeds",
      url:''
    },
   


  ];
  export default blogs;